import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMessageBubble.module.css';

const IconMessageBubble = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="15"
      height="15"
      viewBox="0 0 26 26"
      className={css.icon}
    >
      <path d="M 13 0.1875 C 5.925781 0.1875 0.1875 5.253906 0.1875 11.5 C 0.1875 14.675781 1.675781 17.539063 4.0625 19.59375 C 3.542969 22.601563 0.175781 23.828125 0.40625 24.65625 C 3.414063 25.902344 9.378906 23.011719 10.28125 22.5625 C 11.15625 22.730469 12.070313 22.8125 13 22.8125 C 20.074219 22.8125 25.8125 17.746094 25.8125 11.5 C 25.8125 5.253906 20.074219 0.1875 13 0.1875 Z"></path>
    </svg>
  );
};

IconMessageBubble.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconMessageBubble.propTypes = { rootClassName: string, className: string };

export default IconMessageBubble;
