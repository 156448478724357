import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import { FieldTextInput, RemoveFieldButton } from '../../components';
import { emptyOrValidYoutubeURL } from '../../util/validators';

import css from './EditListingPhotosForm.module.css';

const VIDEO_LINKS = 'videoLinks';

const AddVideoButton = props => {
  const { rootClassName, className, label, id, onClick } = props;
  const classes = classNames(rootClassName || className);

  return (
    <button className={classes} id={id} onClick={onClick} type="button">
      {label}
    </button>
  );
};

const AddVideoLink = props => {
  const { rootClassName, className, label, id, fields, meta, form } = props;
  const classes = classNames(rootClassName || css.videoLinks, className);

  const intl = useIntl();

  const handleRemoveLink = index => {
    if (fields.length !== 1) {
      fields.remove(index);
    } else {
      form.change(`${VIDEO_LINKS}[${index}]`, null);
    }
  };

  const handleAddLink = () => {
    // Set to 'undefined' to create an empty value
    form.mutators.push(VIDEO_LINKS, undefined);
  };

  const addVideoBtnLabel = intl.formatMessage({
    id: 'EditListingPhotosForm.addVideoBtnLabel',
  });

  const addVideoPlaceholder = intl.formatMessage({
    id: 'EditListingPhotosForm.addVideoPlaceholder',
  });

  const videoLinkRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.videoLinkRequiredMessage',
  });

  const videoLinkValidMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.videoLinkValidMessage',
  });

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      {fields.map((name, index) => {
        return (
          <div className={css.videoLink} key={index}>
            <FieldTextInput
              className={css.videoLinkField}
              name={name}
              id={`${name}_${index}`}
              placeholder={addVideoPlaceholder}
              type="text"
              validate={emptyOrValidYoutubeURL(videoLinkValidMessage)}
            />
            <RemoveFieldButton
              className={css.removeFieldButton}
              svgClassName={css.removeFieldButtonSvg}
              onClick={() => handleRemoveLink(index)}
            />
          </div>
        );
      })}
      <AddVideoButton className={css.addMoreBtn} onClick={handleAddLink} label={addVideoBtnLabel} />
    </fieldset>
  );
};

AddVideoLink.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
};

AddVideoLink.propTypes = {
  rootClassName: string,
  className: string,
  label: node,
};

const AddVideoLinks = props => (
  <FieldArray component={AddVideoLink} {...props} name={VIDEO_LINKS} id={VIDEO_LINKS} />
);

AddVideoLinks.propTypes = {
  className: string,
  label: string,
};

export default AddVideoLinks;
