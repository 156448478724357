import React from 'react';
import { NamedLink } from '../../components';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './Categories.module.css';

import Dj from './Images/Dj.svg';
import andre from './Images/andre.svg';
import kokk from './Images/Kokk.svg';
import soloartist from './Images/Soloartist.svg';
import magiker from './Images/Magiker.svg';
import danser from './Images/Danser.svg';
import band from './Images/Band.svg';
import foredragsholder from './Images/Foredragsholder.svg';
import lydlysscene from './Images/lyd,lys,scene.svg';
import komiker from './Images/Komiker.svg';
import instrumentalist from './Images/Instrumentalist.svg';
import konferansier from './Images/Konfransier.svg';

const Categories = props => {
  const { className, rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  const images = (
    <div className={css.listingCards}>
      <NamedLink name="SearchPage" to={{ search: 'pub_talents=dj' }}>
        <img className={css.aspectWrapper} src={Dj} alt="dj" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=soloartist' }}>
        <img className={css.aspectWrapper} src={soloartist} alt="soloartist" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=band' }}>
        <img className={css.aspectWrapper} src={band} alt="band" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=instrumentalist' }}>
        <img className={css.aspectWrapper} src={instrumentalist} alt="instrumentalist" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=komiker' }}>
        <img className={css.aspectWrapper} src={komiker} alt="komiker" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=danser' }}>
        <img className={css.aspectWrapper} src={danser} alt="danser" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=lyd/lys/scene' }}>
        <img className={css.aspectWrapper} src={lydlysscene} alt="lydlydscene" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=foredragsholder' }}>
        <img className={css.aspectWrapper} src={foredragsholder} alt="Foredragsholder" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=konferansier' }}>
        <img className={css.aspectWrapper} src={konferansier} alt="konferansier" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=magiker' }}>
        <img className={css.aspectWrapper} src={magiker} alt="magiker" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=kokk' }}>
        <img className={css.aspectWrapper} src={kokk} alt="Kokk" />
      </NamedLink>

      <NamedLink name="SearchPage" to={{ search: 'pub_talents=andre' }}>
        <img className={css.aspectWrapper} src={andre} alt="Andre" />
      </NamedLink>
    </div>
  );

  return (
  <div>
    {images}
  </div>
  );
};

export default Categories;

