/** NOTE: This code will not be updated!
 *
 * If you want to use this form you need to add the import back to `forms/index.js`
 *
 * We are currently using hosted Connect Onboarding flow provided by Stripe.
 * You can read more from Stripe documentation: https://stripe.com/docs/connect/connect-onboarding
 * If you want to handle creating user's Stripe account and passing the required information to Stripe manually, you can use this page with PayoutDetailsForm as a starting point.
 *
 * Keep in mind that this code might be outdated so make sure you check the current requirements from Stripe and modify the code accordingly.
 *  You can read more about required information from Stripe documentation: https://stripe.com/docs/connect/required-verification-information
 *
 * We might remove this code in the later releases.
 *
 */
import React, { useState, useEffect } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { isStripeInvalidPostalCode, isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';
import { Button, ExternalLink, FieldRadioButton, FieldSelect, Form } from '../../components';
import axios from 'axios';
import PayoutDetailsCompanyAccount from './PayoutDetailsCompanyAccount';
import PayoutDetailsIndividualAccount from './PayoutDetailsIndividualAccount';
import css from './PayoutDetailsForm.module.css';
import '@stripe/stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { post } from '../../util/api';
import FieldOccupationsSelect from './FieldOccupationsSelect';
const sharetribeSdk = require('sharetribe-flex-sdk');
// const Stripe = require('stripe');
const stripe =
  typeof Stripe !== 'undefined' ? Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) : '';
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const supportedCountries = config.stripe.supportedCountries.map(c => c.code);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const stripeCountryConfigs = countryCode => {
  const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
};

const PayoutDetailsFormComponent = props => {
  //for individual
  const [selectedFileFront, setSelectedFileFront] = useState(null);
  const [selectedFileBack, setSelectedFileBack] = useState(null);
  const [adressProofFront, setAdressProofFront] = useState(null);
  const [adressProofBack, setAdressProofBack] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  //for company
  const [companyFileFront, setCompanyFileFront] = useState(null);
  const [companyFileBack, setCompanyFileBack] = useState(null);

  const [companyPersonFileFront, setCompanyPersonFileFront] = useState(null);
  const [companyPersonFileBack, setCompanyPersonFileBack] = useState(null);

  const [companyPersonAdditionalFileFront, setCompanyPersonAdditionalFileFront] = useState(null);
  const [companyPersonAdditionalFileBack, setCompanyPersonAdditionalFileBack] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const spinnerClasses = useStyles();
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          className,
          createStripeAccountError,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          ready,
          submitButtonText,
          currentUserId,
          values,
          onFormSubmit,
        } = fieldRenderProps;

        const { country } = values;

        const accountType = values.accountType;

        const individualAccountLabel = intl.formatMessage({
          id: 'PayoutDetailsForm.individualAccount',
        });

        const companyAccountLabel = intl.formatMessage({
          id: 'PayoutDetailsForm.companyAccount',
        });

        const countryLabel = intl.formatMessage({
          id: 'PayoutDetailsForm.countryLabel',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'PayoutDetailsForm.countryPlaceholder2',
        });
        const countryRequired = validators.required(
          intl.formatMessage({
            id: 'PayoutDetailsForm.countryRequired',
          })
        );

        const classes = classNames(css.root, className, {
          [css.disabled]: disabled,
        });

        const submitInProgress = inProgress;
        const submitDisabled = pristine || invalid || disabled || submitInProgress;
        const showAsRequired = pristine;

        const beforeInfo = country && accountType;
        const showIndividual = beforeInfo && accountType === 'individual';
        const showCompany = beforeInfo && accountType === 'company';

        let error = null;

        if (isStripeInvalidPostalCode(createStripeAccountError)) {
          error = (
            <div className={css.error}>
              <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailedInvalidPostalCode" />
            </div>
          );
        } else if (isStripeError(createStripeAccountError)) {
          const stripeMessage = createStripeAccountError.apiErrors[0].meta.stripeMessage;
          error = (
            <div className={css.error}>
              <FormattedMessage
                id="PayoutDetailsForm.createStripeAccountFailedWithStripeError"
                values={{ stripeMessage }}
              />
            </div>
          );
        } else if (createStripeAccountError) {
          error = (
            <div className={css.error}>
              <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailed" />
            </div>
          );
        }

        const stripeConnectedAccountTermsLink = (
          <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
            <FormattedMessage id="PayoutDetailsForm.stripeConnectedAccountTermsLink" />
          </ExternalLink>
        );

        const submitForm = formValues => {
          return handleSubmit(formValues)
            .then(resp => {
              setShowSpinner(true);
              if (showIndividual) {
                //front of id card upload
                const dataFrontID = new FormData();
                dataFrontID.append('file', selectedFileFront);
                dataFrontID.append('purpose', 'identity_document');

                return fetch('https://uploads.stripe.com/v1/files', {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                  },
                  body: dataFrontID,
                })
                  .then(res => res.json())
                  .then(resp => {
                    const fileDataFrontID = resp;

                    //back of id card upload
                    const dataBackID = new FormData();
                    dataBackID.append('file', selectedFileBack);
                    dataBackID.append('purpose', 'identity_document');
                    return fetch('https://uploads.stripe.com/v1/files', {
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                      },
                      body: dataBackID,
                    })
                      .then(res => res.json())
                      .then(res => {
                        const fileDataBackID = res;

                        //adress proof upload
                        const dataAdressProofFront = new FormData();
                        dataAdressProofFront.append('file', adressProofFront);
                        dataAdressProofFront.append('purpose', 'identity_document');

                        return fetch('https://uploads.stripe.com/v1/files', {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                          },
                          body: dataAdressProofFront,
                        })
                          .then(res => res.json())
                          .then(resp => {
                            const adressProofFrontID = resp;

                            const dataAdressProofBack = new FormData();
                            dataAdressProofBack.append('file', adressProofBack);
                            dataAdressProofBack.append('purpose', 'identity_document');

                            return fetch('https://uploads.stripe.com/v1/files', {
                              method: 'POST',
                              headers: {
                                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                              },
                              body: dataAdressProofBack,
                            })
                              .then(res => res.json())
                              .then(resp => {
                                const adressProofBackID = resp;

                                const fontDoc = fileDataFrontID.id;
                                const backDoc = fileDataBackID.id;
                                const adressDocFront = adressProofFrontID.id;
                                const adressDocBack = adressProofBackID.id;

                                return sdk.stripeAccount
                                  .fetch()
                                  .then(res => {
                                    const stripeAccountId =
                                      res.data.data.attributes.stripeAccountId;

                                    //name
                                    const individualFName = values.individual?.fname;
                                    const individualLName = values.individual?.lname;
                                    //address
                                    const individualCity = values.individual?.address?.city;
                                    const individualPostalCode =
                                      values.individual?.address?.postalCode;
                                    const individualStreetAddress =
                                      values.individual?.address?.streetAddress;
                                    //dob
                                    const individualDobDay = values.individual?.birthDate?.day;
                                    const individualDobMonth = values.individual?.birthDate?.month;
                                    const individualDobYear = values.individual?.birthDate?.year;

                                    const requestOptions = {
                                      type: 'individual',
                                      individualFName,
                                      individualLName,
                                      individualCity,
                                      individualPostalCode,
                                      individualStreetAddress,
                                      individualDobDay,
                                      individualDobMonth,
                                      individualDobYear,
                                      email,
                                      phoneNumber,
                                      fileDataFrontID: fontDoc,
                                      fileDataBackID: backDoc,
                                      additionalDocFront: adressDocFront,
                                      additionalDocBack: adressDocBack,
                                      stripeAccount: stripeAccountId,
                                    };
                                    return (
                                      post('/api/stripe-onboarding-completion', requestOptions)
                                        // post("/api/stripe-onboarding-completion", formData)
                                        .then(response => {
                                          console.log(response);
                                          console.log('SUCCESSS');
                                          // onFormSubmit({});
                                          if (typeof window !== 'undefined') {
                                            window.location.reload();
                                          }
                                          setShowSpinner(false);
                                          return true;
                                        })
                                        .catch(error => {
                                          throw error;
                                        })
                                    );
                                  })
                                  .catch(error => {
                                    throw error;
                                  });
                              })
                              .catch(error => {
                                throw error;
                              });
                          })
                          .catch(error => {
                            throw error;
                          });
                      })
                      .catch(error => {
                        throw error;
                      });
                  })
                  .catch(error => {
                    setShowSpinner(false);
                    if (typeof window !== 'undefined') {
                      window.location.reload();
                    }
                    return console.log(error);
                  });
              } else if (showCompany) {
                //   companyPersonAdditionalFileFront , companyPersonAdditionalFileBack

                //front of company proof
                const dataFrontCompanyProof = new FormData();
                dataFrontCompanyProof.append('file', companyFileFront);
                dataFrontCompanyProof.append('purpose', 'additional_verification');

                return fetch('https://uploads.stripe.com/v1/files', {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                  },
                  body: dataFrontCompanyProof,
                })
                  .then(res => res.json())
                  .then(resp => {
                    const fileDataFrontCompanyDoc = resp;

                    //back of company proof
                    const dataBackCompanyProof = new FormData();
                    dataBackCompanyProof.append('file', companyFileBack);
                    dataBackCompanyProof.append('purpose', 'additional_verification');

                    return fetch('https://uploads.stripe.com/v1/files', {
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                      },
                      body: dataBackCompanyProof,
                    })
                      .then(res => res.json())
                      .then(resp => {
                        const fileDataBackCompanyDoc = resp;

                        //front of company representative id
                        const dataFrontCompanyRepresId = new FormData();
                        dataFrontCompanyRepresId.append('file', companyPersonFileFront);
                        dataFrontCompanyRepresId.append('purpose', 'identity_document');

                        return fetch('https://uploads.stripe.com/v1/files', {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                          },
                          body: dataFrontCompanyRepresId,
                        })
                          .then(res => res.json())
                          .then(resp => {
                            const fileDataFrontCompanyRepresId = resp;

                            //back of company representative id
                            const dataBackCompanyRepresId = new FormData();
                            dataBackCompanyRepresId.append('file', companyPersonFileBack);
                            dataBackCompanyRepresId.append('purpose', 'identity_document');

                            return fetch('https://uploads.stripe.com/v1/files', {
                              method: 'POST',
                              headers: {
                                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                              },
                              body: dataBackCompanyRepresId,
                            })
                              .then(res => res.json())
                              .then(resp => {
                                const fileDataBackCompanyRepresId = resp;

                                //front of company representative ADRESS
                                const dataFrontCompanyRepresAdress = new FormData();
                                dataFrontCompanyRepresAdress.append(
                                  'file',
                                  companyPersonAdditionalFileFront
                                );
                                dataFrontCompanyRepresAdress.append('purpose', 'identity_document');

                                return fetch('https://uploads.stripe.com/v1/files', {
                                  method: 'POST',
                                  headers: {
                                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                                  },
                                  body: dataFrontCompanyRepresAdress,
                                })
                                  .then(res => res.json())
                                  .then(resp => {
                                    const fileDataFrontCompanyRepresAdress = resp;

                                    //back of company representative ADRESS
                                    const dataBackCompanyRepresAdress = new FormData();
                                    dataBackCompanyRepresAdress.append(
                                      'file',
                                      companyPersonAdditionalFileBack
                                    );
                                    dataBackCompanyRepresAdress.append(
                                      'purpose',
                                      'identity_document'
                                    );

                                    return fetch('https://uploads.stripe.com/v1/files', {
                                      method: 'POST',
                                      headers: {
                                        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
                                      },
                                      body: dataBackCompanyRepresAdress,
                                    })
                                      .then(res => res.json())
                                      .then(resp => {
                                        const fileDataBackCompanyRepresAdress = resp;

                                        return sdk.stripeAccount
                                          .fetch()
                                          .then(res => {
                                            const stripeAccountId =
                                              res.data.data.attributes.stripeAccountId;
                                            const firstName = document.getElementById(
                                              'accountOpener.firstName'
                                            ).value;
                                            const lastName = document.getElementById(
                                              'accountOpener.lastName'
                                            ).value;
                                            const birthDateDay = document.getElementById(
                                              'accountOpener.birthDate'
                                            ).value;
                                            const birthDateMonth = document.getElementById(
                                              'accountOpener.birthDate-month'
                                            ).value;
                                            const birthDateYear = document.getElementById(
                                              'accountOpener.birthDate-year'
                                            ).value;

                                            const representativeAdressLine1 = document.getElementById(
                                              'company.address.streetAddress'
                                            ).value;
                                            const representativePostalCode = document.getElementById(
                                              'company.address.postalCode'
                                            ).value;
                                            const representativeCity = document.getElementById(
                                              'company.address.city'
                                            ).value;

                                            //verifDocs

                                            const companyDocFront = fileDataFrontCompanyDoc.id;
                                            const companyDocBack = fileDataBackCompanyDoc.id;
                                            const companyPersonIDFront =
                                              fileDataFrontCompanyRepresId.id;
                                            const companyPersonIDBack =
                                              fileDataBackCompanyRepresId.id;
                                            const companyPersonAdressFront =
                                              fileDataFrontCompanyRepresAdress.id;
                                            const companyPersonAdressBack =
                                              fileDataBackCompanyRepresAdress.id;

                                            const formData = new FormData();

                                            formData.append('type', 'company');

                                            //verif docs

                                            formData.append(
                                              'companyPersonAdressFront',
                                              companyPersonAdressFront
                                            );

                                            formData.append(
                                              'companyPersonAdressBack',
                                              companyPersonAdressBack
                                            );

                                            formData.append(
                                              'companyPersonIDBack',
                                              companyPersonIDBack
                                            );

                                            formData.append(
                                              'companyPersonIDFront',
                                              companyPersonIDFront
                                            );

                                            formData.append('companyDocFront', companyDocFront);

                                            formData.append('companyDocBack', companyDocBack);

                                            // end of verif docs

                                            formData.append('phoneNumber', phoneNumber);

                                            formData.append(
                                              'representativeAdressLine1',
                                              representativeAdressLine1
                                            );

                                            formData.append(
                                              'representativePostalCode',
                                              representativePostalCode
                                            );

                                            formData.append(
                                              'representativeCity',
                                              representativeCity
                                            );

                                            formData.append('firstName', firstName);

                                            formData.append('lastName', lastName);

                                            formData.append('birthDateDay', birthDateDay);

                                            formData.append('birthDateMonth', birthDateMonth);

                                            formData.append('birthDateYear', birthDateYear);

                                            formData.append('stripeAccount', stripeAccountId);
                                            return (
                                              axios({
                                                method: 'post',
                                                url: '/api/stripe-onboarding-completion',
                                                data: formData,
                                                contentType: 'application/json',
                                                headers: {
                                                  'Content-Type': 'multipart/form-data',
                                                },
                                              })
                                                // post("/api/stripe-onboarding-completion", formData)
                                                .then(response => {
                                                  console.log(response);
                                                  console.log('SUCCESSS');
                                                  if (typeof window !== 'undefined') {
                                                    window.location.reload();
                                                  }
                                                  return true;
                                                })
                                                .catch(error => {
                                                  throw error;
                                                })
                                            );
                                          })
                                          .catch(error => {
                                            throw error;
                                          });
                                      })
                                      .catch(error => {
                                        throw error;
                                      });
                                  })
                                  .catch(error => {
                                    throw error;
                                  });
                              })
                              .catch(error => {
                                throw error;
                              });
                          })
                          .catch(error => {
                            throw error;
                          });
                      })
                      .catch(error => {
                        throw error;
                      });
                  })
                  .catch(error => {
                    throw error;
                  });
              }
            })
            .catch(error => {
              if (typeof window !== 'undefined') {
                window.location.reload();
              }
              return console.log(error);
            });
        };

        const isEmailValid = email.includes('@') && email.includes('.') && email.length > 5;
        const isPhoneNumberValid = phoneNumber.length > 7;
        const individualFields =
          !isEmailValid ||
          !isPhoneNumberValid ||
          !selectedFileFront ||
          !selectedFileBack ||
          !adressProofFront ||
          !adressProofFront;
        const submitDisabledIndividual = submitDisabled || individualFields;

        const companyFields =
          !isPhoneNumberValid ||
          !companyFileFront ||
          !companyFileBack ||
          !companyPersonFileFront ||
          !companyPersonFileBack ||
          !companyPersonAdditionalFileFront ||
          !companyPersonAdditionalFileBack;
        const submitDisabledCompany = submitDisabled || companyFields;

        return config.stripe.publishableKey ? (
          <Form className={classes} onSubmit={submitForm}>
            <div className={css.sectionContainer}>
              {showSpinner ? (
                <div className={css.spinnerWrapper}>
                  <div className={css.spinner}>
                    <CircularProgress />
                  </div>
                </div>
              ) : null}

              <h3 className={css.subTitle}>
                <FormattedMessage id="PayoutDetailsForm.accountTypeTitle" />
              </h3>
              <div className={css.radioButtonRow}>
                <FieldRadioButton
                  id="individual"
                  name="accountType"
                  label={individualAccountLabel}
                  value="individual"
                  showAsRequired={showAsRequired}
                />
                <FieldRadioButton
                  id="company"
                  name="accountType"
                  label={companyAccountLabel}
                  value="company"
                  showAsRequired={showAsRequired}
                />
              </div>
            </div>

            {accountType ? (
              <React.Fragment>
                <div className={css.sectionContainer}>
                  {showIndividual && (
                    <FieldOccupationsSelect name="occupation" id="occupation" intl={intl} />
                  )}

                  <FieldSelect
                    id="country"
                    name="country"
                    disabled={disabled}
                    className={css.selectCountry}
                    autoComplete="country"
                    label={countryLabel}
                    validate={countryRequired}
                  >
                    <option disabled value="">
                      {countryPlaceholder}
                    </option>
                    {supportedCountries.map(c => (
                      <option key={c} value={c}>
                        {intl.formatMessage({
                          id: `PayoutDetailsForm.countryNames.${c}`,
                        })}
                      </option>
                    ))}
                  </FieldSelect>
                </div>

                {showIndividual ? (
                  <PayoutDetailsIndividualAccount
                    fieldRenderProps={fieldRenderProps}
                    country={country}
                    currentUserId={currentUserId}
                    setSelectedFileFront={setSelectedFileFront}
                    setSelectedFileBack={setSelectedFileBack}
                    selectedFileFront={selectedFileFront}
                    selectedFileBack={selectedFileBack}
                    setAdressProofFront={setAdressProofFront}
                    adressProofFront={adressProofFront}
                    setAdressProofBack={setAdressProofBack}
                    adressProofBack={adressProofBack}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    email={email}
                    setEmail={setEmail}
                  />
                ) : showCompany ? (
                  <PayoutDetailsCompanyAccount
                    fieldRenderProps={fieldRenderProps}
                    country={country}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setCompanyFileFront={setCompanyFileFront}
                    setCompanyFileBack={setCompanyFileBack}
                    setCompanyPersonFileFront={setCompanyPersonFileFront}
                    setCompanyPersonFileBack={setCompanyPersonFileBack}
                    setCompanyPersonAdditionalFileFront={setCompanyPersonAdditionalFileFront}
                    companyFileFront={companyFileFront}
                    companyFileBack={companyFileBack}
                    companyPersonFileFront={companyPersonFileFront}
                    companyPersonFileBack={companyPersonFileBack}
                    companyPersonAdditionalFileFront={companyPersonAdditionalFileFront}
                    companyPersonAdditionalFileBack={companyPersonAdditionalFileBack}
                    setCompanyPersonAdditionalFileBack={setCompanyPersonAdditionalFileBack}
                  />
                ) : null}

                {error}

                <p className={css.termsText}>
                  <FormattedMessage
                    id="PayoutDetailsForm.stripeToSText"
                    values={{ stripeConnectedAccountTermsLink }}
                  />
                </p>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={showIndividual ? submitDisabledIndividual : submitDisabledCompany}
                  ready={ready}
                >
                  {submitButtonText ? (
                    submitButtonText
                  ) : (
                    <FormattedMessage id="PayoutDetailsForm.submitButtonText" />
                  )}
                </Button>
              </React.Fragment>
            ) : null}
          </Form>
        ) : (
          <div className={css.missingStripeKey}>
            <FormattedMessage id="PayoutDetailsForm.missingStripeKey" />
          </div>
        );
      }}
    />
  );
};

PayoutDetailsFormComponent.defaultProps = {
  className: null,
  createStripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  submitButtonText: null,
  currentUserId: null,
  fieldRenderProps: null,
};

PayoutDetailsFormComponent.propTypes = {
  className: string,
  createStripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  submitButtonText: string,
  currentUserId: propTypes.uuid,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutDetailsForm = compose(injectIntl)(PayoutDetailsFormComponent);

export default PayoutDetailsForm;
