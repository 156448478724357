import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import { FieldTextInput, RemoveFieldButton } from '../../components';
import { emptyOrValidSoundLink } from '../../util/validators';

import css from './EditListingPhotosForm.module.css';

const SOUND_LINKS = 'soundLinks';

const AddSoundButton = props => {
  const { rootClassName, className, label, id, onClick } = props;
  const classes = classNames(rootClassName || className);

  return (
    <button className={classes} id={id} onClick={onClick} type="button">
      {label}
    </button>
  );
};

const AddSoundLink = props => {
  const { rootClassName, className, label, id, fields, meta, form } = props;
  const classes = classNames(rootClassName || css.soundLinks, className);

  const intl = useIntl();

  const handleRemoveLink = index => {
    if (fields.length !== 1) {
      fields.remove(index);
    } else {
      form.change(`${SOUND_LINKS}[${index}]`, null);
    }
  };

  const handleAddLink = () => {
    // Set to 'undefined' to create an empty value
    form.mutators.push(SOUND_LINKS, undefined);
  };

  const addSoundBtnLabel = intl.formatMessage({
    id: 'EditListingPhotosForm.addSoundBtnLabel',
  });

  const addSoundPlaceholder = intl.formatMessage({
    id: 'EditListingPhotosForm.addSoundPlaceholder',
  });

  const soundLinkRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.soundLinkRequiredMessage',
  });

  const soundLinkValidMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.soundLinkValidMessage',
  });

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      {fields.map((name, index) => {
        return (
          <div className={css.soundLink} key={index}>
            <FieldTextInput
              className={css.soundLinkField}
              name={name}
              id={`${name}_${index}`}
              placeholder={addSoundPlaceholder}
              type="text"
              validate={emptyOrValidSoundLink(soundLinkValidMessage)}
            />
            <RemoveFieldButton
              className={css.removeFieldButton}
              svgClassName={css.removeFieldButtonSvg}
              onClick={() => handleRemoveLink(index)}
            />
          </div>
        );
      })}
      <AddSoundButton className={css.addMoreBtn} onClick={handleAddLink} label={addSoundBtnLabel} />
    </fieldset>
  );
};

AddSoundLink.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
};

AddSoundLink.propTypes = {
  rootClassName: string,
  className: string,
  label: node,
};

const AddSoundLinks = props => (
  <FieldArray component={AddSoundLink} {...props} name={SOUND_LINKS} id={SOUND_LINKS} />
);

AddSoundLinks.propTypes = {
  className: string,
  label: string,
};

export default AddSoundLinks;
