import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';

import css from './InvoicePaymentForm.module.css';

export const InvoicePaymentFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, formId, handleSubmit, inProgress, intl } = fieldRenderProps;

      // Company name
      const companyNameLabel = intl.formatMessage({
        id: 'InvoicePaymentForm.companyNameLabel',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'InvoicePaymentForm.companyNamePlaceholder',
      });
      const companyNameRequiredMessage = intl.formatMessage({
        id: 'InvoicePaymentForm.companyNameRequired',
      });

      // VAT number
      const vatNumberLabel = intl.formatMessage({
        id: 'InvoicePaymentForm.vatNumberLabel',
      });
      const vatNumberPlaceholder = intl.formatMessage({
        id: 'InvoicePaymentForm.vatNumberPlaceholder',
      });
      const vatNumberRequiredMessage = intl.formatMessage({
        id: 'InvoicePaymentForm.vatNumberRequired',
      });

      // Address
      const addressLabel = intl.formatMessage({
        id: 'InvoicePaymentForm.addressLabel',
      });
      const addressPlaceholder = intl.formatMessage({
        id: 'InvoicePaymentForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'InvoicePaymentForm.addressRequired',
      });

      // Email address
      const emailLabel = intl.formatMessage({
        id: 'InvoicePaymentForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'InvoicePaymentForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'InvoicePaymentForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'InvoicePaymentForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // Reference
      const referenceLabel = intl.formatMessage({
        id: 'InvoicePaymentForm.referenceLabel',
      });
      const referencePlaceholder = intl.formatMessage({
        id: 'InvoicePaymentForm.referencePlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              className={css.companyName}
              type="text"
              id={formId ? `${formId}.companyName` : 'companyName'}
              name="companyName"
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
              validate={validators.required(companyNameRequiredMessage)}
            />
            <FieldTextInput
              className={css.vatNumber}
              type="number"
              min="0"
              id={formId ? `${formId}.vatNumber` : 'vatNumber'}
              name="vat"
              label={vatNumberLabel}
              placeholder={vatNumberPlaceholder}
              validate={validators.required(vatNumberRequiredMessage)}
            />
            <FieldTextInput
              className={css.address}
              type="text"
              id={formId ? `${formId}.address` : 'address'}
              name="address"
              label={addressLabel}
              placeholder={addressPlaceholder}
              validate={validators.required(addressRequiredMessage)}
            />
            <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.reference}
              type="text"
              id={formId ? `${formId}.reference` : 'reference'}
              name="reference"
              label={referenceLabel}
              placeholder={referencePlaceholder}
            />
          </div>
          <div className={css.submitWrapper}>
            <PrimaryButton type="submit" inProgress={inProgress}>
              <FormattedMessage id="InvoicePaymentForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

export default injectIntl(InvoicePaymentFormComponent);
