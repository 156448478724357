import classNames from 'classnames';
import { bool, node, string } from 'prop-types';
import React from 'react';

import css from './Accordion.module.css';

const Accordion = props => {
  const { id, className, rootClassName, title, isActive, children, onClick } = props;

  const classes = classNames(rootClassName || css.root, className);
  const headerClasses = isActive ? css.accordionHeaderShow : css.accordionHeader;
  return (
    <div className={classes} id={id}>
      <div className={headerClasses} onClick={onClick}>
        {title}
        <div className={css.collapseBtn}>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className={css.accordionContent}>{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  id: string,
  children: node.isRequired,
  title: string,
  isActive: bool,
};

Accordion.defaultProps = {
  isActive: false,
};

export default Accordion;
