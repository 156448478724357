import classNames from 'classnames';
import { isEqual } from 'lodash';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { filterFalsyValueInArray } from '../../util/helperFunc';
import { FormattedMessage } from '../../util/reactIntl';

import css from './EditListingPhotosPanel.module.css';

const EditListingPhotosPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    onUpdateImageOrder,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onChange,
    onSubmit,
  } = props;

  const pageStates = useSelector(state => state.EditListingPage);

  const { profileImage } = pageStates;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);

  const panelTitle = <FormattedMessage id="EditListingPhotosPanel.listingTitle" />;

  // IMAGES HANDLER
  const { profileImageId, soundLinks = [''], videoLinks = [''] } =
    currentListing.attributes.publicData || {};

  // Images are passed to EditListingForm so that it can generate thumbnails out of them
  const currentListingImages = currentListing?.images || [];

  // Images not yet connected to the listing
  const imageOrder = pageStates.imageOrder || [];
  const unattachedImages = imageOrder.map(i => pageStates.images[i]);

  const allImages = currentListingImages.concat(unattachedImages);
  const removedImageIds = pageStates.removedImageIds || [];
  const images = allImages.filter(img => {
    return !removedImageIds.includes(img.id);
  });

  const talentProfileImage =
    profileImage ||
    (profileImageId
      ? images.find(
          image => image?.id?.uuid === profileImageId || image?.imageId?.uuid === profileImageId
        )
      : undefined);

  const talentCoverImages = profileImageId
    ? images.filter(
        image => image?.imageId?.uuid !== profileImageId && image?.id?.uuid !== profileImageId
      )
    : images;

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPhotosForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={{
          images: talentCoverImages,
          profileImage: talentProfileImage,
          videoLinks,
          soundLinks,
        }}
        initialValuesEqual={(oldValues, newValues) => isEqual(oldValues, newValues)}
        images={talentCoverImages}
        onSubmit={values => {
          const { images, profileImage, videoLinks, soundLinks } = values;
          const profileImageId = profileImage?.imageId?.uuid || profileImage?.id?.uuid;

          const updateVideoLinks = filterFalsyValueInArray(videoLinks);
          const updateSoundLinks = filterFalsyValueInArray(soundLinks);
          const updateImages = filterFalsyValueInArray([profileImage, ...images]);

          const updateValues = {
            images: updateImages,
            publicData: {
              profileImageId,
              videoLinks: updateVideoLinks,
              soundLinks: updateSoundLinks,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        profileImage={talentProfileImage}
      />
    </div>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default EditListingPhotosPanel;
