import React from 'react';
import { array, bool, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import { Button, Categories, ListingCard, NamedLink } from '../../components';

import css from './SectionProfiles.module.css';

const SectionProfiles = props => {
  const {
    rootClassName,
    className,
    users,
    listings,
    queryUsersInProgress,
    queryUsersError,
    setActiveListing,
    children,
    cardRenderSizes,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  if (queryUsersInProgress || queryUsersError) {
    return null;
  }

  return (
    <div className={css.sectionContent}>
      <h1>
        <FormattedMessage id="LandingPage.category" />
      </h1>
      <Categories />
      <div className={css.gridcontainer}>
        <h1>
          <FormattedMessage id="LandingPage.recentlyAdded" />
        </h1>
        <NamedLink className={css.padding} name="SearchPage">
          Vis Flere
        </NamedLink>
      </div>
      <div className={css.listingCards}>
        {listings
          .filter((_, index) => index < 3)
          .map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
            />
          ))}
      </div>
    </div>
  );
};

SectionProfiles.defaultProps = {
  rootClassName: null,
  className: null,
  users: [],
  listings: [],
  fetchUsersInProgress: false,
  fetchUsersError: null,
};

SectionProfiles.propTypes = {
  rootClassName: string,
  className: string,
  users: array.isRequired,
  fetchUsersInProgress: bool.isRequired,
  fetchUsersError: propTypes.error,
};

export default SectionProfiles;
