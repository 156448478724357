import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { compose } from 'redux';
import { Accordion, Button, Form } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import EditProductForm from './EditProductForm';

import css from './EditListingProductsForm.module.css';

const PRODUCTS = 'products';
const MAX_PRODUCTS = 10;

const EditListingProductsFormComponent = props => {
  const [activeProductIndex, setActiveProductIndex] = useState(null);
  const [images, setImages] = useState({});

  const { products } = props;

  const handleToggleAccordion = index => {
    setActiveProductIndex(activeIndex => {
      return activeIndex === index ? null : index;
    });
  };

  const handleUploadProductImage = (index, image) => {
    setImages(images => {
      return { ...images, [index]: image };
    });
  };

  const isNoActiveProduct = !!(activeProductIndex !== 0 && !activeProductIndex);

  useEffect(() => {
    setImages(images => {
      const loadedImages = {};
      products.forEach((product, index) => {
        loadedImages[index] = product.productImage;
      });
      return loadedImages;
    });
  }, [products]);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          handleSubmit,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          intl,
          talentOptions,
          vibeOptions,
          genreOptions,
          form,
          values,
          products,
          invalid,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const isNumProductOverLimit = values.products.length >= MAX_PRODUCTS;

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingProductsForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingProductsForm.showListingFailed" />
          </p>
        ) : null;

        const addProductLabel = intl.formatMessage({
          id: 'EditListingProductsForm.addProduct',
        });

        const handleAddProduct = () => {
          if (!isNumProductOverLimit) {
            form.mutators.push(PRODUCTS, '');
          }
        };

        const handleDeleteProduct = (name, index, value) => {
          form.change(`${name}.deleted`, true);
          setActiveProductIndex(null);
          setImages(images => {
            return { ...images, [index]: null };
          });
        };

        const handleSubmitProducts = e => {
          setActiveProductIndex(null);
          handleSubmit(e);
        };

        // This button is only available when the num of products is less than the maximum limit
        const addProductBtn = !isNumProductOverLimit && (
          <div className={css.addProductBtn} onClick={handleAddProduct}>
            {addProductLabel}
          </div>
        );

        return (
          <Form className={classes} onSubmit={handleSubmitProducts}>
            {errorMessage}
            {errorMessageShowListing}

            <FieldArray name={PRODUCTS}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  const isActive = activeProductIndex === index;
                  const draftTitle = intl.formatMessage(
                    {
                      id: 'EditListingProductsForm.draftProductTitle',
                    },
                    { number: index + 1 }
                  );
                  const title = products[index]?.name || draftTitle;

                  return (
                    !fields.value[index].deleted && (
                      <Accordion
                        className={css.accordion}
                        title={title}
                        isActive={isActive}
                        key={index}
                        onClick={() => handleToggleAccordion(index)}
                      >
                        <EditProductForm
                          name={name}
                          talentOptions={talentOptions}
                          vibeOptions={vibeOptions}
                          genreOptions={genreOptions}
                          form={form}
                          productImage={images[index]}
                          setProductImage={image => handleUploadProductImage(index, image)}
                          onDeleteProduct={() => handleDeleteProduct(name, index)}
                        />
                      </Accordion>
                    )
                  );
                });
              }}
            </FieldArray>

            {addProductBtn}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingProductsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingProductsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingProductsForm = EditListingProductsFormComponent;

export default compose(injectIntl)(EditListingProductsForm);
