import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingPayoutForm.module.css';
import PayoutDetailsForm from '../PayoutDetailsForm/PayoutDetailsForm';
import { ensureCurrentUser } from '../../util/data';

const EditListingPayoutFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        // stripe
        currentUser,
        scrollingDisabled,
        createStripeAccountError,
        onPayoutDetailsFormChange,
        onPayoutDetailsFormSubmit,
        payoutDetailsSaveInProgress,
        payoutDetailsSaved,
        intl,
        stripeConnectStatusBox,
        currentListing,
        stripeDetailsMissing,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      //stripe

      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const currentUserLoaded = !!ensuredCurrentUser.id;
      const stripeConnected =
        currentUserLoaded &&
        !!ensuredCurrentUser.stripeAccount &&
        !!ensuredCurrentUser.stripeAccount.id;

      const formDisabled = !currentUserLoaded || stripeConnected || payoutDetailsSaved;

      let stripeAccountBox = null;
      if (currentUserLoaded && stripeConnected) {
        stripeAccountBox = stripeConnectStatusBox;
      }

      const showForm =
        currentUserLoaded &&
        (payoutDetailsSaveInProgress || payoutDetailsSaved || !stripeConnected);
      const form = showForm ? (
        <PayoutDetailsForm
          disabled={formDisabled}
          inProgress={payoutDetailsSaveInProgress}
          ready={payoutDetailsSaved}
          submitButtonText={intl.formatMessage({ id: 'PayoutPreferencesPage.submitButtonText' })}
          createStripeAccountError={createStripeAccountError}
          onChange={onPayoutDetailsFormChange}
          onSubmit={onPayoutDetailsFormSubmit}
          onFormSubmit={() => handleSubmit({})}
          currentUserId={ensuredCurrentUser.id}
        />
      ) : null;

      const isListingDraft = currentListing?.attributes?.state === 'draft';
      const showPublishProfileButton = currentUserLoaded && stripeConnected && isListingDraft;
      const publishListingButton = (
        <Button type="button" disabled={stripeDetailsMissing} onClick={() => handleSubmit({})}>
          {intl.formatMessage({ id: 'EditListingWizard.publishProfile' })}
        </Button>
      );
      return (
        <>
          {stripeAccountBox}
          {form}
          {showPublishProfileButton && publishListingButton}
        </>
      );
    }}
  />
);

EditListingPayoutFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingPayoutFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingPayoutForm = EditListingPayoutFormComponent;

export default EditListingPayoutForm;
