import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import { array, bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Button, Form } from '../../components';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import AddCoverImages from './AddCoverImages';
import AddProfileImage from './AddProfileImage';
import AddSoundLinks from './AddSoundLinks';
import AddVideoLinks from './AddVideoLinks';

import css from './EditListingPhotosForm.module.css';

const EditListingPhotosForm = props => {
  const [profileImageUploading, setProfileImageUploading] = useState(false);
  const [coverImageUploading, setCoverImageUploading] = useState(false);
  const [submittedImages, setSubmittedImages] = useState([]);

  const intl = useIntl();

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          form,
          className,
          fetchErrors,
          handleSubmit,
          images,
          invalid,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          profileImage,
        } = formRenderProps;

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        let uploadImageFailed = null;

        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        }

        // NOTE: These error messages are here since Photos panel is the last visible panel
        // before creating a new listing. If that order is changed, these should be changed too.
        // Create and show listing errors are shown above submit button
        const publishListingFailed = publishListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
          </p>
        ) : null;
        const showListingFailed = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
          </p>
        ) : null;

        const submittedOnce = submittedImages.length > 0;
        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || profileImageUploading || coverImageUploading;
        const classes = classNames(css.root, className);

        const videoLinksLabel = intl.formatMessage({
          id: 'EditListingPhotosForm.videoLinksLabel',
        });

        const soundLinksLabel = intl.formatMessage({
          id: 'EditListingPhotosForm.soundLinksLabel',
        });

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              handleSubmit(e);
            }}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.updateFailed" />
              </p>
            ) : null}
            {publishListingFailed}
            {showListingFailed}
            <AddProfileImage
              profileImage={profileImage}
              form={form}
              profileImageUploading={profileImageUploading}
              setProfileImageUploading={setProfileImageUploading}
            />
            <AddCoverImages
              images={images}
              form={form}
              coverImageUploading={coverImageUploading}
              setCoverImageUploading={setCoverImageUploading}
            />

            {uploadImageFailed}

            <AddVideoLinks className={css.videoLinks} label={videoLinksLabel} form={form} />

            <AddSoundLinks className={css.soundLinks} label={soundLinksLabel} form={form} />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosForm.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosForm.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default EditListingPhotosForm;
