import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const { payinTotal, payoutTotal } = transaction.attributes;

  const providerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  // Provider commission fee percentage will always be negative
  const providerCommisionFeePercentage =
    providerCommissionLineItem?.percentage.toNumber() / 100 || 0;

  const { offerPrice } = transaction.attributes?.protectedData || {};

  const totalPrice = isProvider ? payoutTotal : payinTotal;

  // Calculate the amount of total price if new offer exists, otherwise same as default (only for provider)
  const estimatedTotalPrice =
    offerPrice && providerCommissionLineItem
      ? isProvider
        ? offerPrice.amount + offerPrice.amount * providerCommisionFeePercentage
        : offerPrice.amount
      : null;
  const finalTotalPrice = estimatedTotalPrice
    ? new Money(estimatedTotalPrice, offerPrice.currency)
    : totalPrice;
  const formattedTotalPrice = formatMoney(intl, finalTotalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={estimatedTotalPrice ? css.totalLabelBold : css.totalLabel}>
          {totalLabel}
        </div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
