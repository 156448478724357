import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  FieldSelect,
  FieldTextInput,
  Form,
  IconSpinner,
  PrimaryButton,
  ToggleButton,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { composeValidators, required, validPositiveIntegerNumber } from '../../util/validators';

import css from './BookingTimeForm.module.css';

const DEBOUNCE_TIME = 500;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.debounce = this.debounce.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      timer: null,
    };
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  debounce(func, time = DEBOUNCE_TIME) {
    clearTimeout(this.state.timer);
    this.setState({
      timer: window.setTimeout(func, time),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, product: productIndex } = formValues.values;

    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const isOwnListing = this.props.isOwnListing;
    const listingId = this.props.productListingOptions[productIndex]?.id;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (
      bookingStartTime &&
      bookingEndTime &&
      listingId &&
      !isSameTime &&
      !this.props.fetchLineItemsInProgress &&
      formValues.valid
    ) {
      this.debounce(() =>
        this.props.onFetchTransactionLineItems({
          bookingData: { startDate, endDate },
          listingId,
          isOwnListing,
        })
      );
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            productListingOptions,
            eventTypeOptions,
            invalid,
            initiateOrderInProgress,
          } = fieldRenderProps;

          const submitDisabled = invalid || fetchLineItemsInProgress || fetchLineItemsError;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                  productListing: productListingOptions[values.product],
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const requiredMessage = intl.formatMessage({ id: 'BookingTimeForm.required' });
          const validNumberOfGuestMessage = intl.formatMessage({
            id: 'BookingTimeForm.validNumberOfGuestMessage',
          });

          const productPlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.productPlaceholder',
          });
          const eventTypePlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.eventTypePlaceholder',
          });
          const productLabel = intl.formatMessage({ id: 'BookingTimeForm.productLabel' });
          const locationLabel = intl.formatMessage({ id: 'BookingTimeForm.locationLabel' });
          const eventTypeLabel = intl.formatMessage({ id: 'BookingTimeForm.eventTypeLabel' });
          const numGuestLabel = intl.formatMessage({ id: 'BookingTimeForm.numGuestLabel' });
          const extraInfoLabel = intl.formatMessage({ id: 'BookingTimeForm.extraInfoLabel' });
          const provideTechnicalEquipmentLabel = intl.formatMessage({
            id: 'BookingTimeForm.provideTechnicalEquipmentLabel',
          });
          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <FieldSelect
                className={css.field}
                id="product"
                name="product"
                label={productLabel}
                validate={required(requiredMessage)}
              >
                <option disabled value="">
                  {productPlaceholder}
                </option>
                {productListingOptions.map((option, index) => (
                  <option value={index} key={`${option}-${index}`}>
                    {option.title}
                  </option>
                ))}
              </FieldSelect>
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                />
              ) : null}
              <FieldTextInput
                className={css.field}
                id="location"
                name="location"
                label={locationLabel}
                type="text"
                validate={required(requiredMessage)}
              />
              <FieldSelect
                className={css.field}
                id="eventType"
                name="eventType"
                label={eventTypeLabel}
                validate={required(requiredMessage)}
              >
                <option disabled value="">
                  {eventTypePlaceholder}
                </option>
                {eventTypeOptions.map((option, index) => (
                  <option value={option.value} key={`${option}-${index}`}>
                    {option.label}
                  </option>
                ))}
              </FieldSelect>
              <FieldTextInput
                className={css.field}
                id="numGuest"
                name="numGuest"
                label={numGuestLabel}
                type="text"
                validate={required(requiredMessage)}
                onKeyPress={e => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                }}
              />
              <FieldTextInput
                className={css.field}
                id="extraInfo"
                name="extraInfo"
                label={extraInfoLabel}
                type="textarea"
              />

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={initiateOrderInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  productListings: [],
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  productListings: array,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;

/* Booking toggel button
  <div className={css.provideTechnicalEquipmentField}>
                <ToggleButton
                  className={css.provideTechnicalEquipmentFieldSwitch}
                  id={'shouldProvideTechnicalEquipment'}
                  name={'shouldProvideTechnicalEquipment'}
                />
                <label className={css.provideTechnicalEquipmentFieldLabel}>
                  {provideTechnicalEquipmentLabel}
                </label>
              </div>

*/
