import { bool, func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AddSingleImage, ValidationError } from '../../components';
import { uploadImageToFlex } from '../../containers/EditListingPage/EditListingPage.duck';
import { required } from '../../util/validators';

import css from './EditListingProductsForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const AddProductImage = props => {
  // STATES
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const onUploadProductImage = data => dispatch(uploadImageToFlex(data));

  // INTL
  const intl = useIntl();

  // PROPS
  const {
    name,
    form,
    uploadImageInProgress,
    setUploadImageInProgress,
    setProductImage,
    productImage,
  } = props;

  // METHODS
  const onRemoveProductImage = () => setProductImage(null);

  const onProductImageUploadHandler = file => {
    if (file) {
      const tempImage = { id: `${file.name}_${Date.now()}`, file };
      setUploadImageInProgress(true);
      setProductImage(tempImage);

      //Upload the image to sdk
      onUploadProductImage(tempImage)
        .then(res => {
          const image = { ...tempImage, imageId: res.data.data.id };
          form.change(`${name}.productImage`, image);
          setProductImage(image);
        })
        .catch(e => {
          setError(e);
        })
        .finally(() => {
          setUploadImageInProgress(false);
        });
    }
  };

  // RENDERING
  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.imageRequired',
  });

  const addProductImageTitle = intl.formatMessage({
    id: 'EditListingProductsForm.addProductImageTitle',
  });

  return (
    <div className={css.productImage}>
      <h1>{addProductImageTitle}</h1>
      <AddSingleImage
        className={css.imagesField}
        image={productImage}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingProductsForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveProductImage}
      >
        <Field
          id={`${name}.addProductImage`}
          name={`${name}.addProductImage`}
          accept={ACCEPT_IMAGES}
          label={chooseImageText}
          type="file"
          disabled={uploadImageInProgress}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              form.change(`${name}.addProductImage`, file);
              form.blur(`${name}.addProductImage`);
              onProductImageUploadHandler(file);
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field>

        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={meta} />
              </div>
            );
          }}
          name={`${name}.productImage`}
          type="hidden"
          validate={required(imageRequiredMessage)}
        />
      </AddSingleImage>
      <p className={css.error}>{error && error.message}</p>
    </div>
  );
};

AddProductImage.defaultProps = {
  productImage: null,
};

AddProductImage.propTypes = {
  name: string,
  productImage: object,
  uploadImageInProgress: bool,
  setUploadImageInProgress: func,
};

export default AddProductImage;
