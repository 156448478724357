import React, { Component, useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { Form, Button, NamedLink } from '../../components';
import css from './FilterLandingPageForm.module.css';

const URL_PARAM_TALENTS = 'pub_talents';
const URL_PARAM_ARANGEMENT = 'pub_arrangements';
const URL_PARAM = 'pub_performingLocations';

const FilterLandingPageForm = props => {
  const { viewOptions, viewOptionsA, viewOptionsL, sendSuggestionButton } = props;
  const [current, setCurrent] = useState('');

  //For the test dropdown 
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const currentFilter = viewOptionsA.find(a => a.key === viewOptionsA);
    setCurrent(currentFilter);
  }, []);

 // State to manage the open/closed state of the dropdown
  const [selectedLabel, setSelectedLabel] = useState('velg underholdning'); // State to store the selected label

  const handleSubmit = (label) => {
    setSelectedLabel(label); // Update the selected label when an option is clicked
    setIsOpen(false); // Close the dropdown after an option is clicked
  }

  const buttons = (
    <div className={css.buttonlayout}>
      <button>Søk blandt våre profiler</button>
    </div>
  );

  const filterTalents = (
    <div >
     
    </div>
  );

  const filterLocations = (
    <div>
      <select className={css.responsiveButton} id="performingLocations" name={[URL_PARAM]}>
      <option value="" defaultValue>
          velg lokasjon
        </option>
        {viewOptionsL.map(l => (
          <option key={l.key} value={l.key} className={css.dropdownitem}>
            {l.label}
          </option>
        ))}
      </select>
    </div>
  );

  const filterArrangements = (
    <div>
      <select
        className={css.responsiveButton}
        options={viewOptionsA}
        id="arrangement"
        name={[URL_PARAM_ARANGEMENT]}
      >
        <option value="" defaultValue>
          velg arrangement
        </option>
        {viewOptionsA.map(a => (
          <option key={a.key} value={a.key} className={css.dropdownitem}>
            {a.label}
          </option>
        ))}
      </select>
    </div>
  );

  const test = (
    <div>
    <p onClick={() => setIsOpen(!isOpen)} defaultValue={selectedLabel} className={css.dropdownbutton}>
     {selectedLabel}
    </p>
    {isOpen && (
      <ul className={css.dropdowncontent}>
        {viewOptionsA.map(a => (
          <li
            className={css.dropdownitem}
            key={a.key}
            value={a.label}
            onClick={() => handleSubmit(a.label)}>
              {a.label}
          </li>
          
        ))}
      </ul>
    )}
  </div>
  );
 
  
  return (
    <>
      <Form method={'push'} action={'/'} >
        <div className={css.textaliment}>
          <FormattedMessage id="FilterLandingPageForm.entertainment" />
          <select className={css.dropdownbutton} name={[URL_PARAM_TALENTS]} id="talents">
        <option value="" defaultValue>
          velg underholdning
        </option>
        {viewOptions.map(t => (
          <option key={t.key} value={t.key}>
            {t.label}
          </option>
        ))}
      </select>
          <FormattedMessage id="FilterLandingPageForm.to" />
          {filterArrangements}
        </div>
        <div className={css.textaliment}>
          <FormattedMessage id="FilterLandingPageForm.eventType" />
          {filterLocations}
         
        </div>
        {buttons}
      </Form>
    </>
  );
};

export default FilterLandingPageForm;
