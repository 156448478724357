import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMapsPin.module.css';

const IconMapsPin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 5 64 64"
    >
      <path d="M25.026 46.678l23.281-28.251C49.382 20.733 50 23.293 50 26c0 6.01-2.48 9.345-4.669 12.288-.781 1.05-1.596 2.052-2.46 3.111-2.92 3.586-6.23 8.119-8.904 15.269C33.612 57.62 32.913 58 32 58s-1.612-.38-1.967-1.332C28.507 52.589 26.775 49.364 25.026 46.678zM33.991 32.655L23.853 44.956c-.925-1.302-1.843-2.474-2.725-3.557-.863-1.06-1.679-2.061-2.46-3.111-.416-.559-.843-1.133-1.261-1.737l8.681-10.549C26.035 26.327 26 26.659 26 27c0 3.314 2.686 6 6 6C32.699 33 33.367 32.874 33.991 32.655zM29.885 21.391l9.644-11.719c3.206 1.484 5.897 3.879 7.75 6.858l-9.35 11.346C37.971 27.589 38 27.298 38 27c0-3.314-2.686-6-6-6C31.254 21 30.543 21.142 29.885 21.391zM26.227 22.688l-9.948 12.087C14.995 32.536 14 29.805 14 26c0-3.942 1.289-7.581 3.446-10.549L26.227 22.688zM27.498 21.144l-8.787-7.242C22.006 10.286 26.735 8 32 8c1.948 0 3.82.32 5.578.895L27.498 21.144z"></path>
    </svg>
  );
};

IconMapsPin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconMapsPin.propTypes = { rootClassName: string, className: string };

export default IconMapsPin;
