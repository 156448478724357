import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 22, 2019</p>

      <p>
      Om Bookd AS
Hei, og velkommen til Bookd! 
BOOKD AS er en digital tjeneste dedikert til formidling og booking arrangementer og formidling av talenter som kan tilby sine tjenester gjennom plattformen. BOOKD AS legger også til rette for at Brukere skal få lokal og relevant informasjon om hva som skjer i sin by, i tillegg til lokale og relevante tilbud fra BOOKD AS sine samarbeidspartnere.
 
Før du registrerer deg på Appen må du lese og godta Appens Brukervilkår og Personvernerklæring. 
 
Brukervilkårene forklarer vilkårene for din bruk av Appen, mens Personvernerklæringen forklarer mer i detalj hvilke personopplysninger som må behandles, og hvilke informasjonskapsler (cookies) vi bruker. 
      </p>
      <h4> Dette er de viktigste tingene å være oppmerksom på i Appens Brukervilkår: </h4>

      <li>Du må være minst 18 år for å være en registrert bruker av Appen. </li>
      <li>Ved registrering som ny bruker i Appen må du verfisiere deg med e-mail.</li>
      <li>For at Bookd skal kunne administrere og drifte Appen og gi deg tilgang til tjenestene som er tilgjengelige der, vil du bli bedt om å oppgi ditt navn, e-postadresse og telefonnummer. </li>
      <li>Vi vil bare behandle personopplysningene dine for å levere de tjenestene som er beskrevet her i Brukervilkårene til Appen. </li>
      <li>Vi vil aldri selge eller gi bort dine personopplysninger til andre tredjeparter enn de som eksplisitt er omtalt i Appens Brukervilkår. </li> 
      <li>Appen er designet med tanke på innebygd personvern, såkalt «privacy by design» og «privacy by default». Dette betyr at Appen er designet for å håndtere personopplysninger på en forsvarlig måte etter dataminimerings- og separasjonsprinsipper, og den er innstilt med den mest personvernvennlige standardinnstillingen når du tar den i bruk.</li>

     <h4>Sist endret 02.08.2022</h4>
     <h3>1. Om Bookd App</h3>
      <p>
      Bookd App (heretter Appen).
Bookd AS med organisasjonsnummer 926 646 389 og forretningsadresse Bankplassen 1A, 0150 Oslo (heretter Leverandøren), har utviklet denne Appen.
Hensikten med Appen er å gjøre bookingprosessen enklere for deg som Arrangør/ Talent/ Bookingbyrå.
Disse generelle vilkårene (heretter Brukervilkårene) regulerer bruken av Appen mellom deg som bruker av Appen, Arrangør/Talent/Bookingbyrå og Leverandøren. Vilkårene gjelder i tillegg til eventuelle brukervilkår til nåværende eller fremtidige tilleggstjenester i Appen. Leverandøren kan til enhver tid oppdatere Appen og implementere ny funksjonalitet som blir kommunisert til deg som bruker via Appen.
Leverandøren har særlig vektlagt brukerfunksjonalitet og personvern under utviklingen av Appen.
Leverandøren har alle immaterielle rettigheter til Appen.
      </p>

      <h3>2. Definisjoner</h3>
      <p>
      Talenter: Med Talent menes en aktør som tilbyr varer eller tjenester som næringsvirksomhet i Tjenesten.
Klienter: Med Klienter menes de som ønsker å kjøpe varer eller tjenester via Tjenesten. 
App: Med App menes Tjenesten som tilbys til brukere i form av apper eller web-basert format.
Bruker: Med bruker menes de som benytter seg av Tjenesten både klienter og talenter. Dette vil primært være personlige Brukere, men kan også være andre typer Brukere (som BOOKD AS-administrasjon, eventplanleggere, andre tjenestetilbydere innenfor samme næring, eller ikke-kommersielle aktører som ser det som hensiktsmessig å benytte Tjenesten. Det vil fremgå av Brukerprofilen dersom en Bruker ikke er personlig Bruker.
Part: Med Part menes en Bruker som har brukt Tjenesten til å inngå en avtale, eksempelvis om å booke et talent til sitt ønskede arrangement. Disse Brukerne omtales da som Part/Parter i forbindelse med transaksjonen eller avtalen som gjennomføres.
Tjenesten: Med Tjenesten menes Appene BOOKD AS som er en plattform som matcher behov og tilbud mellom kunder og talenter. Tjenesten vil også gi brukerne relevant informasjon og tilbud knyttet tjenesten som tilbys. Tjenesten omfatter Brukergrensesnittet, sentralserver som behandler data og de applikasjonsspesifikke tjenestene tilbudt av tredjepartsleverandører (slik som for eksempel bildelagring og Brukerautentisering).

      </p>

      <h3>2. Bruksrett
</h3>
      <p>
      Ved godkjenning av disse Brukervilkårene gis du en, personlig, ikke-eksklusiv, gjenkallelig og ikke-overførbar bruksrett til å bruke Appen i nåværende og fremtidige versjoner i henhold til Brukervilkårene. Betaling for bruken av Appen skjer ved at Leverandøren trekker avtalt prosentandel av det avtalte honoraret mellom Arrangør/Talent/Bookingbyrå, eller gjennom en avtalt abonnementsmodell.
Du kan ikke kopiere, modifisere, distribuere eller selge noen del av programvaren eller tjenestene som leveres av Leverandøren ut over det som er følger av Brukervilkårene eller annen skriftlig avtale med Leverandøren.
      </p>
      <h3>
3. Bruk av Bookd-Appen
</h3>
   <p>Appen er bare tilgjengelig for personer som er bosatt i Norge og som er over 18 år. Ved å ta i bruk denne Appen innestår du for at du er over 18 år.
Appen leveres som den er («as is») uten noen garantier for oppetid, funksjonalitet eller support, heller ikke fra tjenester levert av tredjeparter i tilknytning til Appen. Leverandøren har ikke ansvar for kvaliteten eller validiteten av noe innhold som presenteres i Appen, på Bookd.no eller websider det lenkes til eller i tilhørende dokumentasjon.
Du er selv ansvarlig for bruken av Appen og er forpliktet til å bruke Appen med tilknyttede tjenester kun til det formål som følger av Brukervilkårene og innenfor begrensninger i gjeldende lov eller spesielle vilkår oppgitt for særskilte tjenester i Appen som du bruker.
 </p>
   <h3>3.1 Bruker</h3>
   <p>Bruker skal oppgi fullstendige og korrekte personopplysninger. I de tilfeller personinformasjon hentes ut fra dataregistre skal Brukeren straks varsle BOOKD AS om den informasjonen som blir presentert ikke er riktig. Brukeren skal ikke fremstå med annet navn enn sitt eget i BOOKD AS.
BookD skal holde orden på hvilken verifisering (om noen) man har gjennomført på Brukere. Brukere vil kunne ha ulike nivå av verifikasjon, som varierer fra BankID til ingen verifisering.
</p>
<h3> 
3.2 Innholdsproduksjon
</h3>
<p>Bruker skal selv påse at det man publiserer i Tjenesten er passende, lovlig og relevant i relasjon til Tjenestens formål.
Brukeren må selv eie eller på annet vis ha rett til å råde over tjenester som tilbys gjennom BOOKD AS.
Brukeren har rettighet til alt innhold som man selv publiserer i tjenesten. 
Brukeren kan ikke kopiere, distribuere, publisere eller selge informasjon fra Tjenesten.
</p>
<h3>3.3 Betaling og skatt</h3>
<p>BOOKD AS er ikke Part i et det skattemessige oppgjøret. BOOKD AS håndterer kun transaksjonen mellom partene som bekrefter at bookingen er bekreftet mellom partene. 
Det er Brukerens og Talentenes ansvar å påse at man opererer i tråd med gjeldende lovgivning, herunder skatteregler. I den utstrekning varer og tjenester som utveksles gjennom tjenesten utløser skatte- eller avgiftsplikt, er det Brukernes og Talentenes ansvar alene å sørge for at skatter og avgifter blir betalt. Appen inneholder en side med ofte stilte spørsmål som vil holdes oppdatert med relevante referanser til regelverk. Det henvises ellers til Skatteetatens hjemmesider.
Dersom skattemyndighetene henvender seg til BOOKD AS for opplysninger fra Tjenesten vil BOOKD AS gi slik informasjon i den utstrekning myndighetene har lovlig hjemmel til å kreve dette.
Oppgjøret mellom partene betales ut etter 7 dager etter avtalt booking. Om annet avtales eller defineres mellom partene må dette varsles til hello@bookd.no i forkant av inngått avtale mellom partene. Eventuelle avgifter vil spesifiseres gjennom tjeneste</p>

   <h3>4. Bruk av personopplysninger</h3>
   <p>I forbindelse med første registrering som ny bruker i Appen må du må du benytte e-post. Dette for at Leverandøren skal kunne validere din identitet. Når du senere logger deg på Appen, vil du kun bli bedt om å oppgi din e-postadresse og ditt passord.

<li>Opplysningene vil bli brukt i den art og utstrekning det er nødvendig for at Leverandøren skal kunne levere (administrere og drifte) Appen med oppgitt aktivitet:
Opprette en konto, og logge inn og ut av denne.</li>
<li>Få oversikt over bookingavtaler.</li>
<li>Kontakte og bli kontaktet av Leverandøren eller Arrangør/Talent/Bookingbyrå.</li>
<li>Ha et arkiv for dokumenter relatert til bookingavtalen.</li>
<li>Følge med på Artister/Bookingbyrå som legges ut på Bookd.</li>
 
Behandlingsgrunnlaget for ovennevnte formål er avtale, 
det vil si disse Brukervilkårene og Appens Personvernerklæring. Du finner detaljer om Bookds behandling av personopplysninger i personvernerklæringen.

Ovennevnte behandlinger inkluderer overføring til og behandling av personopplysninger i skytjenesten
 Google Cloud, samt Sharetribe. Se punkt 3 og 4 i Personvernerklæringen for nærmere detaljer om leverandør og underleverandører og tilknyttede databehandleravtaler.

Du kan i tillegg avgi et frivillig,
 uttrykkelig og informert samtykke under registrering eller senere på Min profil i Appen, slik at enkelte kontaktopplysninger om deg (navn, e-postadresse og telefonnummer) videreformidles til våre samarbeidspartnere, slik at de kan tilby deg relevante tjenester. Du finner en oppdatert oversikt over våre betrodde samarbeidspartnere på denne siden. Dersom du skal kunne bli kontaktet av våre Talent/Bookingbyrå via e-post, må du avgi samtykke til dette under registrering, eller senere på Min side. Samtykket kan trekkes når som helst, ved å endre innstillingen på Min side.

Leverandøren bruker informasjonskapsler 
(cookies) i Appen. Du finner detaljert informasjon om Google Analytics og de relevante cookiene i punkt 5 i Personvernerklæringen. Under registrering vil du bli anmodet om å samtykke eller ikke samtykke til bruk av cookies. Samtykke kan senere trekkes eller gis ved å endre innstilling på Min side. Det gjøres oppmerksom på at brukervennligheten i Appen kan bli noe dårligere dersom cookies ikke tillates. Du kan samtykke til, f.eks. ved bruk av innstillinger for cookies, at Appen er enklere å benytte ved at du godtar forhåndsutfylling av registreringsfelt, slik som navn og adresse.

Leverandøren vil aldri bruke innsamlede personopplysninger til andre formål eller i annen utstrekning enn det som er uttrykkelig beskrevet i disse Brukervilkårene og Leverandørens Personvernerklæring. Leverandøren vil aldri selge eller gi bort personopplysninger til andre tredjeparter enn de som eksplisitt er omtalt i Brukervilkårene eller Personvernerklæringen.Brukere som ønsker at opplysninger om seg selv skal slettes eller endres eller har spørsmål vedrørende bruk av personopplysninger eller Appen, kan gjøre dette ved å sende epost til Bookd (hello@bookd.no). 
</p>

<h3>5. Endring og terminering av Brukervilkårene</h3>
<p>BOOKD AS tar forbehold om at det vil kunne skje endringer i Tjenesten og Brukervilkårene i fremtiden. Ved mindre endringer vil vi normalt kunngjøre dette direkte i Tjenesten. Dersom vi gjennomfører vesentlige endringer, vil du motta varsel direkte gjennom tilgjengelige kanaler som eksempelvis gjennom Tjenesten, e-post eller SMS. Om du ikke ønsker å godta vilkårene etter endring, kan du si opp avtalen med øyeblikkelig virkning ved å slette din brukerprofil. Dersom du senere tar i bruk Appen igjen, anses dette som ny godkjennelse av avtalen.

Vesentlige brudd på Brukervilkårene gir Leverandøren rett til å terminere avtalen og dermed din bruk av Appen med umiddelbar virkning. Ved terminering fra Leverandørens side med umiddelbar virkning har Leverandøren rett til å stenge din tilgang umiddelbart og uten ytterligere varsel. BOOKD AS forbeholder seg rett til å utføre ethvert tiltak som finnes nødvendig for å forhindre eller stanse enhver form for misbruk av Tjenesten. Ved mistanke om brudd på Brukervilkårene/misbruk av Tjenesten, forbeholder BOOKD AS seg rett til å slette, blokkere eller deaktivere Bruker etter eget skjønn og uten at dette gir krav på noen kompensasjon. All bruk av Tjenesten skal være i tråd med Brukervilkårene, innenfor norsk lovgivning og gjeldende moralnormer, samt ikke bryte med BOOKD AS’ verdigrunnlag. Brudd på dette anses som misbruk av Tjenesten.
Eksempler på misbruk av tjenesten kan være en Bruker som opptrer i uprofesjonelt eller for øvrig i strid med Brukervilkårene, oppfordrer til straffbare handlinger, driver spam/trakassering eller sjikane, eller krenker personvernet eller opphavsrettigheter. Det er herunder forbudt å publisere innhold i strid med diskrimineringslovgivningen eller straffeloven. Innhold som publiseres i strid med dette punkt må påregnes slettet eller endret uten varsel.

Du kan selv terminere denne avtalen med umiddelbar virkning ved å slette din brukerprofil på Appen eller ved å sende e-post til Bookd (hello@bookd.no). Det må påregnes at bruk av sistnevnte alternativ vil innebære noe saksbehandlingstid. Ved terminering vil Leverandøren slette alle eventuelle opplysninger tilknyttet deg og dette avtaleforholdet, med mindre Leverandøren er lovpålagt å oppbevare opplysningene.
</p>
<h3> 
6. Ansvarsfraskrivelse</h3>
<p>Brukerne har selv ansvaret og risikoen for de tjenester og handlinger de avtaler gjennom tjenesten. Dette inkluderer at de selv må vurdere alle forhold rundt arrangementet når man inngår en avtale.

Det understrekes at avtaler gjennom Bookd skjer på vanlig måte. Appen tilbys utelukkende som en ekstra kommunikasjonskanal som kan brukes av deg som  Arrangør/Talent/Bookingbyrå og for å oppnå bedre dialog og/eller for å tilby deg en mulighet til bedre oversikt over hele bookingprosessen. BOOKD AS er ikke ansvarlig for eventuell skade eller ødeleggelse på gjenstander som tilbys gjennom tjenesten. Dette ansvaret faller utelukkende på de involverte Parter og det er de enkelte Parters ansvar å inngå en avtale som står i forhold til det arrangementet som tilbys.

Leverandøren tilbyr Appen slik den er og fraskriver seg ethvert ansvar for eventuell skade eller tap som følge av bruk av Appen. Dersom du oppdager feil i Appen, bes du kontakte support hos Bookd så raskt som mulig.

Tjenesten er underlagt strenge sikkerhetskrav, men kan likevel ikke garantere at Tjenesten til enhver tid er feilfri eller tilgjengelig for Brukeren.

BOOKD AS har ikke kontroll over det digitale innholdet som lastes opp, sendes gjennom Tjenesten eller avtaler som inngås ved hjelp av Tjenesten. Bookd kan derfor ikke holdes ansvarlig på noen måte for bruken av Tjenesten eller innhold i denne.

BOOKD AS kan ikke gjøres ansvarlig for skade, kostnad eller tap knyttet til bruk av Tjenesten og fraskriver seg ethvert ansvar for direkte eller indirekte tap, konsekvens- eller følgeskader av noen art forbundet med bruken av Tjenesten.
</p>
   <h3>7. Funksjoner  </h3>
   <p>For å gjøre Tjenesten så enkel som mulig å bruke ber vi om tilgang til noen funksjoner på enhetene dine. Disse inkluderer:
Push-utsending. Push-utsendelse på telefonen er foretrukket varsling for relevante forespørsler/tilbud fra naboer eller samtaler man har gående med andre personer. Push-utsendelse kan også bli brukt for varsling av arrangementer og annen markedsføring. Bruker kan selv gjøre innstillinger på push-utsendelse i App.
Bildefunksjoner. Appen ber om tilgang til Kamera og Bildegalleri for å kunne legge ut eget profilbilde og for å legge ved bilder i forespørsler og i samtaler.
Lese SMS. Den eneste informasjon som hentes ut fra SMS er autentiserings-koden som mottas ved innlogging med mobilnummer. Tjenesten har da mulighet til å automatisk legge inn autentiseringskoden og sparer Brukeren for å taste denne manuelt.
Sende SMS: informasjon og bekreftelse av bookingen sendes automatisk til både talent og bruker ved inngått avtale. Denne SMSen brukes for å linke sammen bookingen som er gjennomført. 
</p>
<h3> 
8. Lovvalg og tvisteløsning</h3>
<p>Brukervilkårene skal reguleres og tolkes i samsvar med til enhver tid gjeldende norsk rett. Tvister skal være underlagt norske domstolers jurisdiksjon. Oslo tingrett avtales som vedtatt verneting.
</p>
<h3>9. Avbestillingsvilkår</h3>
<p>Ved avlysning fra arrangør innen fire uker før oppdragsdato utbetales 100% av honoraret. Utsettelse regnes som avlysning og en ny avtale må inngås ved eventuell ny dato. Ingen av partene er ansvarlig overfor den andre for mangelfull eller forsinket levering i tilfeller hvor krig, opprør, naturkatastrofe, streik, lockout, offentlige påbud, eller liknende ekstraordinær og upåregnelig hendelse som gjør det praktisk umulig å oppfylle i samsvar med avtalen. Avlysning grunnet COVID-19 regnes ikke som Force Majeure.
</p>
<h3> 10. Personvern og sletting</h3>
<p>Personvern i Tjenesten er nærmere beskrevet i dokumentet BEHANDLING AV PERSONOPPLYSNINGER I BOOKD AS.  BOOKD AS forbeholder seg retten til å utlevere lagrede data etter pålegg fra offentlig myndighet eller domstol.
Bruker kan når som helst slette egen brukerkonto og App.</p>
<h3>11. Kontakt BOOKD AS.</h3>
<p>Dersom du har spørsmål knyttet til BookD kan du kontakte BOOKD på hello@bookd.no. Bruker kan selv rapportere misbruk i App ved å bruke innebygd funksjon i App. </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
