import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      className={css.root}
      viewBox="0 0 64 64"
    >
      <path d="M 23.773438 12 C 12.855437 12 12 12.854437 12 23.773438 L 12 40.226562 C 12 51.144563 12.855438 52 23.773438 52 L 40.226562 52 C 51.144563 52 52 51.145563 52 40.226562 L 52 23.773438 C 52 12.854437 51.145563 12 40.226562 12 L 23.773438 12 z M 21.167969 16 L 42.832031 16 C 47.625031 16 48 16.374969 48 21.167969 L 48 42.832031 C 48 47.625031 47.624031 48 42.832031 48 L 38.617188 48 L 38.617188 36.039062 L 43.353516 36.039062 L 44.099609 30.716797 L 38.617188 30.716797 C 38.617188 30.716797 38.609187 27.599266 38.617188 26.822266 C 38.633187 25.301266 39.904094 24.531969 40.996094 24.542969 C 42.088094 24.554969 44.349609 24.546875 44.349609 24.546875 L 44.349609 19.640625 C 44.349609 19.640625 42.391891 19.386234 40.337891 19.365234 C 38.611891 19.347234 36.705969 19.815234 35.167969 21.365234 C 33.602969 22.941234 33.356172 25.289203 33.326172 28.158203 C 33.317172 28.987203 33.326172 30.714844 33.326172 30.714844 L 28.691406 30.714844 L 28.691406 36.037109 L 33.326172 36.037109 L 33.326172 48 L 21.167969 48 C 16.374969 48 16 47.624031 16 42.832031 L 16 21.167969 C 16 16.374969 16.374969 16 21.167969 16 z"></path>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
