import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import IconTrashBin from '../IconTrashBin/IconTrashBin';

import css from './RemoveFieldButton.module.css';

const RemoveFieldButton = props => {
  const intl = useIntl();
  const { className, rootClassName, svgClassName, onClick, hasText } = props;
  const classes = classNames(rootClassName || css.root, className);
  const removeText = intl.formatMessage({
    id: 'RemoveFieldButton.removeText',
  });

  return (
    <button className={classes} onClick={onClick} type="button">
      <IconTrashBin rootClassName={svgClassName} />
      {hasText && <span>{removeText}</span>}
    </button>
  );
};

RemoveFieldButton.defaultProps = { className: null, rootClassName: null, hasText: false };

const { func, string } = PropTypes;

RemoveFieldButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default RemoveFieldButton;
