import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build SaleActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptError,
    declineError,
    onAcceptSale,
    onDeclineSale,
    makeOfferInProgress,
    makeOfferError,
    openMakeOfferForm,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress || makeOfferInProgress;

  const acceptErrorMessage = acceptError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const makeOfferErrorMessage = makeOfferError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.makeOfferFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
        {makeOfferErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          rootClassName={css.acceptButton}
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>

        <SecondaryButton
          rootClassName={css.declineButton}
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>

        <SecondaryButton
          rootClassName={css.otherActionButton}
          inProgress={makeOfferInProgress}
          disabled={buttonsDisabled}
          onClick={openMakeOfferForm}
        >
          <FormattedMessage id="TransactionPanel.makeOfferButton" />
        </SecondaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
