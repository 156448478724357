import { array, bool } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AddImages, ValidationError } from '../../components';
import {
  removeListingImage,
  requestImageUpload,
} from '../../containers/EditListingPage/EditListingPage.duck';
import { nonEmptyArray } from '../../util/validators';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const AddCoverImages = props => {
  const dispatch = useDispatch();
  const onUploadCoverImages = data => dispatch(requestImageUpload(data));
  const onRemoveCoverImage = data => dispatch(removeListingImage(data));

  const intl = useIntl();

  const { form, coverImageUploading, setCoverImageUploading, images } = props;

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );

  const onCoverImageUploadHandler = file => {
    if (file) {
      setCoverImageUploading(true);
      onUploadCoverImages({ id: `${file.name}_${Date.now()}`, file }).finally(() => {
        setCoverImageUploading(false);
      });
    }
  };

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.imageRequired',
  });

  const addCoverImageTitle = intl.formatMessage({
    id: 'EditListingPhotosForm.addCoverImagesTitle',
  });

  return (
    <div className={css.profileImage}>
      <h1>{addCoverImageTitle}</h1>
      <AddImages
        className={css.imagesField}
        images={images}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveCoverImage}
      >
        <Field
          id="addImage"
          name="addImage"
          accept={ACCEPT_IMAGES}
          label={chooseImageText}
          type="file"
          disabled={coverImageUploading}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              form.change(`addImage`, file);
              form.blur(`addImage`);
              onCoverImageUploadHandler(file);
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field>

        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={meta} />
              </div>
            );
          }}
          name="images"
          type="hidden"
          validate={nonEmptyArray(imageRequiredMessage)}
        />
      </AddImages>

      <p className={css.tip}>
        <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
      </p>
    </div>
  );
};

AddCoverImages.defaultProps = {
  images: [],
};

AddCoverImages.propTypes = {
  images: array,
  imageUploadRequested: bool,
};

export default AddCoverImages;
