import React, { useState } from 'react';
import { string, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, ExternalLink, Modal, Button } from '../../components';
import config from '../../config';
import { FilterLandingPageForm } from '../../forms';

import css from './SectionHero.module.css';

//BookingForm
const renderBookingLink = intl => {
  const { sendRequestForm } = config;

  const sendRequestLink = sendRequestForm ? (
    <div key="linkToRequest">
      <h4>
        <FormattedMessage id="LandingPage.InfoText.forSendRequest" />
        <ExternalLink key="linkToRequest" href={sendRequestForm} className={css.infoSpacer}>
          <FormattedMessage id="LandingPage.sendRequestButton" />
        </ExternalLink>
      </h4>
    </div>
  ) : null;
  return [sendRequestLink].filter(v => v != null);
};

const SectionHero = props => {
  const {
    className,
    rootClassName,
    label,
    customLabel,
    twoColumns,
    threeColumns,
    viewOptions,
    viewOptionsA,
    viewOptionsL,
    intl,
    onManageDisableScrolling,
    sendSuggestionButton,
  } = props;

  const [mounted, setMounted] = useState(false);
  const bookingForm = renderBookingLink(intl);

  const handleOpen = () => {
    setOpen(true);
  };

  const [isOpen, setOpen] = useState(false);

  //Filter on landingpage
  const dropdownFilter = (
    <div >
      <FilterLandingPageForm
        viewOptions={viewOptions}
        viewOptionsA={viewOptionsA}
        viewOptionsL={viewOptionsL}
      />
    </div>
  );

  const classes = classNames(rootClassName || css.root, className);

  const formattedLabel = customLabel || (label ? <FormattedMessage id={label} /> : null);

  const listClasses = threeColumns
    ? classNames(css.list, css.threeColumns)
    : twoColumns
    ? classNames(css.list, css.twoColumns)
    : css.list;

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <p className={classNames(css.heroMainTitle)}>
          <FormattedMessage id="SectionHero.title" />
        </p>
        <h4>
          <FormattedMessage id="Footer.organizationDescription" />
        </h4>
        {dropdownFilter}
        {sendSuggestionButton}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
