import { bool, object } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ValidationError } from '../../components';
import AddSingleImage from '../../components/AddSingleImage/AddSingleImage';
import {
  removeProfileImage,
  requestProfileImageUpload,
} from '../../containers/EditListingPage/EditListingPage.duck';
import { required } from '../../util/validators';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const AddProfileImage = props => {
  const dispatch = useDispatch();
  const onUploadProfileImage = data => dispatch(requestProfileImageUpload(data));
  const onRemoveProfileImage = data => dispatch(removeProfileImage(data));

  const intl = useIntl();

  const { profileImage, form, profileImageUploading, setProfileImageUploading } = props;

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );

  const onProfileImageUploadHandler = file => {
    if (file) {
      setProfileImageUploading(true);
      onUploadProfileImage({ id: `avatar_${file.name}_${Date.now()}`, file }).finally(() => {
        setProfileImageUploading(false);
      });
    }
  };

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.imageRequired',
  });

  const addProfileImageTitle = intl.formatMessage({
    id: 'EditListingPhotosForm.addProfileImageTitle',
  });

  return (
    <div className={css.profileImage}>
      <h1>{addProfileImageTitle}</h1>
      <AddSingleImage
        className={css.imagesField}
        image={profileImage}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveProfileImage}
      >
        <Field
          id="addProfileImage"
          name="addProfileImage"
          accept={ACCEPT_IMAGES}
          label={chooseImageText}
          type="file"
          disabled={profileImageUploading}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              form.change(`addProfileImage`, file);
              form.blur(`addProfileImage`);
              onProfileImageUploadHandler(file);
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field>

        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={meta} />
              </div>
            );
          }}
          name="profileImage"
          type="hidden"
          validate={required(imageRequiredMessage)}
        />
      </AddSingleImage>

      <p className={css.tip}>
        <FormattedMessage id="EditListingPhotosForm.addProfileImageTip" />
      </p>
    </div>
  );
};

AddProfileImage.defaultProps = {
  profileImage: null,
};

AddProfileImage.propTypes = {
  profileImage: object,
  profileImageUploading: bool,
};

export default AddProfileImage;
