/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const filters = [
  {
    id: 'dates-length',
    label: 'Dato',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',
    },
  },
  {
    id: 'price',
    label: 'Pris',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 150000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Nøkkelord',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'talents',
    label: <FormattedMessage id="Listing.talentsLabel" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_talents'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'andre', label: 'Andre' },
        { key: 'soloartist', label: 'Soloartist' },
        { key: 'konferansier', label: 'Konferansier' },
        { key: 'foredragsholder', label: 'Foredragsholder' },
        { key: 'band', label: 'Band' },
        { key: 'kokk', label: 'Kokk' },
        { key: 'komiker', label: 'Komiker' },
        { key: 'dj', label: 'DJ' },
        { key: 'magiker', label: 'Magiker' },
        { key: 'danser', label: 'Danser' },
        { key: 'instrumentalist', label: 'Instrumentalist' },
        { key: 'lyd/lys/scene', label: 'Lyd/Lys/Scene' },
        { key: 'foto/ video', label: 'Foto/ Video' },
      ],
    },
  },
  {
    id: 'arrangements',
    label: <FormattedMessage id="Listing.arrangementsLabel" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_arrangements'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'kickoff', label: 'Kick-off' },
        { key: 'barneselskap', label: 'Barneselskap' },
        { key: 'begravelse', label: 'Begravelse' },
        { key: 'bryllup-i-kirke', label: 'Bryllup i kirke' },
        { key: 'bryllupsfest', label: 'Bryllupsfest' },
        { key: 'fest', label: 'Fest' },
        { key: 'bursdag-barn', label: 'Bursdag (barn)' },
        { key: 'bursdag-ungdom', label: 'Bursdag (ungdom)' },
        { key: 'bursdag-voksen', label: 'Bursdag (voksen)' },
        { key: 'Jubileum', label: 'Jubileum' },
        { key: 'Bedriftsarrangementer', label: 'Bedriftsarrangementer' },
        { key: 'blåtur', label: 'Blåtur' },
        { key: 'event', label: 'Event' },
        { key: 'festival', label: 'Festival' },
        { key: 'firmafest', label: 'Firmafest' },
        { key: 'hotell-pub-klubb', label: 'Hotell/pub/klubb' },
        { key: 'julebord', label: 'Julebord' },
        { key: 'konferanse', label: 'Konferanse' },
        { key: 'konsert', label: 'Konsert' },
        { key: 'lanseringsfest', label: 'Lanseringsfest' },
        { key: 'oktoberfest', label: 'Oktoberfest' },
        { key: 'sommerfest', label: 'Sommerfest' },
        { key: 'seminar', label: 'Seminar' },
        { key: 'skoleball', label: 'Skoleball' },
        { key: 'russeslipp', label: 'Russeslipp' },
        { key: 'annet', label: 'Annet' },
      ],
    },
  },
  {
    id: 'performingLocations',
    label: <FormattedMessage id="Listing.performingLocationsLabel" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_performingLocations'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'akershus', label: 'Akershus' },
        { key: 'oslo', label: 'Oslo' },
        { key: 'hedmark', label: 'Hedmark' },
        { key: 'oppland', label: 'Oppland' },
        { key: 'buskerud', label: 'Buskerud' },
        { key: 'vestfold', label: 'Vestfold' },
        { key: 'telemark', label: 'Telemark' },
        { key: 'aust-agder', label: 'Aust-Agder' },
        { key: 'vest-agder', label: 'Vest-Agder' },
        { key: 'rogaland', label: 'Rogaland' },
        { key: 'hordaland', label: 'Hordaland' },
        { key: 'bergen', label: 'Bergen' },
        { key: 'sogn-og-fjordane', label: 'Sogn og Fjordane' },
        { key: 'møre-og-romsdal', label: 'Møre og Romsdal' },
        { key: 'sør-trøndelag', label: 'Sør-Trøndelag' },
        { key: 'nord-trøndelag', label: 'Nord-Trøndelag' },
        { key: 'nordland', label: 'Nordland' },
        { key: 'troms', label: 'Troms' },
        { key: 'finnmark', label: 'Finnmark' },
        { key: 'østfold', label: 'Østfold' },
      ],
    },
  },
  {
    id: 'vibes',
    label: <FormattedMessage id="Listing.vibesLabel" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_generalVibes'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'intensely', label: 'Intenst' },
        { key: 'sad', label: 'Trist' },
        { key: 'funny', label: 'Morsomt' },
        { key: 'beautifully', label: 'Vakkert' },
        { key: 'moving', label: 'Rørende' },
        { key: 'tøft', label: 'Tøft' },
        { key: 'sprudlende', label: 'Sprudlende' },
        { key: 'energisk', label: 'Energisk' },
        { key: 'romantisk', label: 'Romantisk' },
        { key: 'fest', label: 'Fest' },
      ],
    },
  },
  {
    id: 'genres',
    label: <FormattedMessage id="Listing.genresLabel" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_generalGenres'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'lounge', label: 'Lounge' },
        { key: 'elektronika', label: 'Elektronika' },
        { key: 'house', label: 'House' },
        { key: 'rnb', label: 'RnB/Hip-hop' },
        { key: 'pop', label: 'Pop' },
        { key: 'hits', label: 'Hits' },
        { key: 'kunst', label: 'Kunst' },
        { key: 'street', label: 'Street' },
        { key: 'kommersiell', label: 'Kommersiell' },
        { key: 'dancehall', label: 'Dancehall' },
        { key: 'techno', label: 'Techno' },
        { key: 'folkemusikk', label: 'Folkemusikk' },
        { key: 'jazz', label: 'Jazz' },
        { key: 'disco', label: 'Disco' },
        { key: 'funk', label: 'Funk' },
        { key: 'indie', label: 'Indie' },
        { key: 'rock', label: 'Rock' },
        { key: 'humor', label: 'Humor' },
        { key: 'country', label: 'Country' },
        { key: 'soul', label: 'Soul' },
        { key: 'klassisk', label: 'Klassisk' },
        { key: 'blues', label: 'Blues' },
        { key: 'latino', label: 'Latino' },
        { key: 'k-pop', label: 'K-Pop' },
        { key: 'Akustisk', label: 'Akustisk' },
        { key: 'punk', label: 'Punk' },
        { key: 'metal', label: 'Metal' },
        { key: 'afro', label: 'Afro' },
        { key: 'allsang', label: 'Allsang' },
      ],
    },
  },
  {
    id: 'occuppations',
    type: null,
    config: {
      options: [
        { key: 3473109, label: 'Standup komiker' },
        { key: 3473107, label: 'Klovn' },
        { key: 3473101, label: 'Tryllekunstner/magiker' },
        { key: 2553101, label: 'Artist' },
        { key: 2553107, label: 'Operasanger' },
        { key: 2554104, label: 'Danser' },
        { key: 7312109, label: 'Musikkinstrumentoperatør' },
        { key: 5149108, label: 'Discjockey' },
        { key: 5149119, label: 'Quizmaster' },
        { key: 3349136, label: 'Foredragsholder' },
        { key: 4222116, label: 'Konferansier (mannlig)' },
        { key: 4222117, label: 'Konferansier (kvinnelig)' },
        { key: 3131110, label: 'Lydtekniker' },
        { key: 3131123, label: 'Lystekniker' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Nyeste' },
    { key: '-createdAt', label: 'Eldste' },
    { key: '-price', label: 'Lavest pris' },
    { key: 'price', label: 'Høyest pris' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};
