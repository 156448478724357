import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Versjon 2, oppdatert 07. september 2022</p>

      <p>
      Hei, og velkommen til Bookd! BOOKD AS er en digital tjeneste dedikert til formidling og
       booking arrangementer og formidling av talenter som kan tilby sine tjenester 
       gjennom plattformen. BOOKD AS legger også til rette for at Brukere skal få 
       lokal og relevant informasjon om hva som skjer i sin by, i tillegg til lokale 
       og relevante tilbud fra BOOKD AS sine samarbeidspartnere.
      </p>

      <p>
      Før du registrerer deg på Appen må du lese og godta Appens Brukervilkår og Personvernerklæring. 
      </p>

      <p>
        Brukervilkårene forklarer vilkårene for din bruk av Appen, 
        mens Personvernerklæringen forklarer mer i detalj hvilke personopplysninger
         som må behandles, og hvilke informasjonskapsler (cookies) vi bruker.</p>

     
      <p>
       BOOKD AS er en digital tjeneste dedikert til formidling og booking arrangementer 
       og formidling av talenter som kan tilby sine tjenester gjennom plattformen. 
      </p>

      <p>
      Det er viktig for oss i Bookd at du vet hvilke opplysninger vi behandler 
      om deg når du har tatt i bruk Bookd («Appen»), og at du føler deg 
      trygg på at personvernet ditt er godt ivaretatt. Denne personvernerklæringen gir deg informasjon om personopplysningene som samles inn, hvorfor vi samler inn opplysningene, hvordan de behandles, og dine rettigheter knyttet til behandlingen av personopplysninger.
      </p>
      <p>
      Bookd er forpliktet til å beskytte din personlige informasjon når du 
      bruker Appen. Derfor vil all vår behandling av personopplysninger følge det til enhver tid gjeldende personvernregelverket, herunder de krav som følger av personopplysningsloven og EUs personvernforordning kalt GDPR. For BOOKD-medlemmer hentes primært fra medlemsregisteret. 
      Denne informasjonen kan vedlikeholdes på “ MIN SIDE” i løsningen.
      </p>

      <p>
      Dersom du har spørsmål knyttet til BOOKD, ønsker innsyn eller vil be om retting eller sletting, kan du kontakte BOOKD på hello@bookd.no.
      </p>

      <h3> 1. Hvem er behandlingsansvarlig?</h3>

      <p>Det er Bookd AS med organisasjonsnummer 926 646 389 og forretningsadresse Fredensborgveien 22f, 0177 Oslo («Leverandøren»), ved administrerende direktør som har hovedansvaret for all behandling av personopplysninger som foretas gjennom Appen.
         Ansvaret for den daglige oppfølgningen av etterlevelsen av regelverket ligger hos behandlingsansvarlig hos Leverandøren. For spørsmål du måtte ha om behandling av dine personopplysninger via Appen, kan du kontakte behandlingsansvarlig eller personvernkoordinator hos Bookd AS.
           Kontaktinformasjonen til behandlingsansvarlig hos Leverandøren er:
      <p> Navn: Bookd AS</p> 
      <p>  Adresse: Fredensborgveien 22f, 0177 Oslo</p> 
       <p>  E-post: hello@bookd.no</p> 
       </p>

     <h3>2. Hvilke personopplysninger samles inn, hva er formålet og hvilke behandlingsgrunnlag benyttes?</h3>
     <p>
     Fra det tidspunkt du starter å bruke Bookd Appen, vil Leverandøren behandle visse personopplysninger om deg. Dette vil skje fra du registrerer deg som ny bruker av Appen.
      Vi behandler følgende personopplysninger om deg:
     </p>
     <li> Navn</li>
     <li> Fødselsdato</li>
     <li> Alder</li>
     <li> Personnummer</li>
     <li> E-postadresse</li>
     <li> Telefonnummer</li>
     <li> Adresse</li>
     <li> Bilder</li>
     <li> Brukergenerert innhold</li>
     <li> Bruk av tjenesten</li>
     <li> Teknologi og teknisk informasjon om enheten som er i bruk</li>

     <h3>Førstegangsregistrering:</h3>
     <p>Ved registrering som ny bruker i Appen må du verifisere deg. Dette for at Leverandøren skal kunne:</p>
     <li>Validere din alder.</li>
     <li>Identifisere deg som fullmaktsinnehaver av selskapet du representerer.</li>
     <p>I forbindelse med registrering må du oppgi navn, e-postadresse og telefonnummer. E-post er nødvendig for at vi skal kunne sende deg en verifikasjons-e-post i forbindelse med registrering. For å hente ut informasjon og for å verifisere en bruker, vil vedkommende bli bedt om å logge inn med SMS-kode eller BankID. Bruker har mulighet til å rette eventuelle feil og/eller gjøre endringer.
Når du senere logger deg på Appen, vil du kun bli bedt om å oppgi din e-postadresse og ditt passord.
</p>
      <h3>Avtale som behandlingsgrunnlag:</h3>
      <p>Leverandøren vil benytte dine personopplysninger så langt det er nødvendig for at Leverandøren skal kunne levere (administrere og drifte) Appen med oppgitt funksjonalitet. Vi behandler personopplysninger om deg for å utføre følgende behandlingsaktiviteter:
<p>1.Opprette en konto for deg i Appen, og logge deg inn og ut av denne brukerkontoen.</p>
<p>2.For at du skal kunne få oversikt over tilgjengelige Arrangører/Talenter/Bookingbyrå.</p>
<p>3.For at du skal kunne kontakte og bli kontaktet av Bookd-Arrangører/Talenter/Bookingbyrå.</p>
<p>4.For at du skal kunne ha et arkiv for dokumenter relatert til dine bookinger.</p>



Behandlingsgrunnlaget for ovennevnte aktiviteter er avtalen du inngår med Leverandøren. Avtalen består av Brukervilkår og Personvernerklæring for Appen, og avtalen anses inngått når du har registrert deg som bruker, og du krysser av boksen som viser at du har godkjent Brukervilkår og Personvernerklæring for Appen.
Ovennevnte behandlinger inkluderer også overføring til og behandling av personopplysninger i skytjenesten Google Cloud. Se punkt 3 og 4 nedenfor for nærmere detaljer om deling av personopplysninger med tredjeparter.
</p>

<h3>Samtykke som behandlingsgrunnlag:</h3>
<p>Ved bruk av Appen avgir du samtykke til at Leverandøren benytter tekst og bilde som lastes opp i Appen til markedsføring av Leverandøren. Samtykket kan trekkes når som helst, ved å kontakte Bookd på mail - hello@bookd.no. Samtykke som behandlingsgrunnlag gjelder når formålet med behandlingen av opplysningene faller utenfor det som er nødvendig for å oppfylle avtalen med deg eller administrere deg som bruker.
Leverandøren bruker informasjonskapsler (cookies) i Appen. Vi benytter Google Analytics, Firebase, UX Cam og Hotjar for å analysere bruken av Appen. Du vil bli anmodet om å samtykke eller ikke samtykke til bruk av cookies. Samtykke kan senere trekkes tilbake. Vi gjør deg oppmerksom på at brukervennligheten i Appen kan bli noe dårligere dersom du ikke tillater cookies. Les mer om cookies under punkt 5.
</p>

<h3>Lov som behandlingsgrunnlag:</h3>
<p>I noen tilfeller har Leverandøren en lovpålagt plikt til å behandle dine personopplysninger. I så fall er behandlingsgrunnlaget for behandlingen av dine personopplysninger være lov. Dette er aktuelt i de tilfellene der Leverandøren er pålagt å lagre visse personopplysninger, eksempelvis i forbindelse med kundekontroll, undersøkelse og rapportering etter hvitvaskingsloven.</p>


<h3>
3. Leverandørens sikring av dine personopplysninger</h3>
 <p>Bookd har stort fokus på sikkerhet og kontroll knyttet til registrering og behandling av personopplysninger.
Leverandøren understreker at Appen er designet etter prinsippet innebygd personvern, såkalt «privacy by design» og «privacy by default». Dette betyr at Appen er designet for å håndtere personopplysninger på en forsvarlig måte etter dataminimerings- og separasjonsprinsipper, og at standardinnstillingene er mest mulig personvernvennlige. Dette betyr at Leverandøren har lagt in «opt-in» muligheter for deg som kunde, som innebærer at du må avgi et aktivt og informert samtykke for at Leverandøren skal tilby deg tilleggstjenester, se punkt 2 ovenfor.
Leverandøren arbeider systematisk med å ivareta tekniske og organisatoriske sikkerhetstiltak for å beskytte personopplysninger mot uautorisert tilgang og bruk, samt fra å bli ødelagt eller slettet. Leverandøren vurderer jevnlig risikoen for svikt og avvik i våre sikkerhetssystemer og rutiner.
Data lagres i Google Cloud. Google Cloud Platform, som tilbys av Google, er en serie med cloud computing-tjenester som kjører på den samme infrastrukturen som Google bruker internt for sluttbrukerprodukter, som Google Søk, Gmail, fillagring og YouTube. Data lagres på sikre servere som tilfredsstiller alle standardkrav til IT-sikkerhet. Tilgang til personopplysninger er regulert og begrenset til Leverandørens eller databehandlerens medarbeider som har nødvendig behov. De som har tilgang, har signert taushetserklæring.

Andre som kan få tilgang til opplsyninger:
Andre brukere av BOOKD vil kunne bli presentert informasjon som profilbilde, navn og beskrivelse. Videre opplysninger kan tilgjengeliggjøres, men dette vil da være styrt i form av innstillinger i egen profil. Tjenesten legger videre opp til at brukere kan ha personlig dialog. BOOKD driver ingen generell overvåking av personlige dialoger, men kan sjekke innhold i disse ved mistanke om misbruk av Tjenesten.
Annonsører og selskap vil kunne ta kontakt med deg når du eventuelt samtykker til dette eller ber om dette. Din kontaktinformasjon vil i slike tilfeller kunne overleveres til tredjepart.
Underleverandører til Tjenesten i den grad dette er nødvendig for å gjennomføre deres leveranser til BOOKD.
Tredjepartsleverandører vil få de opplysninger som er nødvendig for å gjennomføre autentisering av en bruker eller for å kunne gjøre oppslag i dataregistre. Se beskrivelse i tidligere avsnitt.
Offentlige organer (f.eks Politi, Skatteetaten) forutsatt at disse har lovhjemmel for å kreve utlevert opplysninger lagret i Tjenesten.
BOOKD kan gi fra seg data til forskningsrelaterte formål. Detaljer om enkeltindivider skal anonymiseres eller data gis på aggregert nivå.</p>

<h3>4. Utlevering av personopplysninger til andre</h3>
<p>Leverandøren gir ikke personopplysningene dine videre til andre med mindre det foreligger et lovlig grunnlag for slik utlevering. Eksempler på slikt grunnlag vil være avtalen med deg eller et lovgrunnlag som pålegger Leverandøren å gi ut informasjonen.
Leverandøren vil aldri selge eller gi bort personopplysninger til andre tredjeparter enn de som eksplisitt er omtalt i disse vilkårene.
Når det gjelder eventuell overføring av informasjon til tredjeparter i forbindelse med registrering av aktivitet ved bruk av Appen, vises det til punkt 5 under om informasjonskapsler (cookies) ved bruk av Appen.
Behandling av personopplysninger på vegne av Leverandørens kunder skjer innenfor EU/EØS-området. Personopplysninger lagres på servere i EU. Det er kun anonymiserte data generert gjennom cookies (Google Analytics og Mailchimp) som overføres til USA.
Utlevering av personopplysninger kan også skje i forbindelse med virksomhetsoverdragelse, fusjon, oppkjøp, sammenslåing eller deling av Leverandørens virksomhet.
</p>
<h3>5. Informasjonskapsler (cookies)</h3>
<p>Informasjonskapsler (cookies) er små tekstfiler som lagres på din nettleser på enheten du bruker når du benytter Appen. Cookies inneholder verken virus eller programmer. Når du besøker nettstedet bookd.no registrerer Leverandøren informasjon om ditt besøk med en såkalt cookie, for eksempel hvor mange ganger/hvor lenge du har besøkt nettsiden, når du besøkte nettsiden sist og hva som er kilden til besøket (eksempelvis Google søk).
Leverandøren bruker informasjonskapsler for å generere brukerstatistikk og for å forbedre og utvikle nettsiden. Leverandøren vil ikke bruke informasjonskapsler til å kontakte deg eller for å gi deg spesielle tilbud. Det samles ikke inn personlig informasjon om brukeren, kun anonymiserte data som dine klikk og pek på nettsiden.
Ved første bruk av Appen (registrering) får du mulighet til å godta eller avvise bruk av informasjonskapsler som brukes i forbindelse med Google Analytics. Dersom du velger ikke å akseptere bruken av cookies, kan det føre til at nettsiden ikke fungerer optimalt. Du kan selv trekke samtykket eller avgi samtykke til bruk av informasjonskapsler på Min side i Appen.
Google Analytics er en nettanalysetjeneste som leveres av Alphabet Inc. (Google). Google Analytics bruker cookies for å analysere hvordan brukere benytter nettstedet. Informasjonen som genereres av en slik cookie inkludert din IP-adresse, sendes til Google og lagres på servere i USA. Leverandøren bruker anonymizeIP, en funksjon som gjør at en enkeltbruker ikke kan identifiseres. Google bruker denne informasjonen for å vurdere bruken av Appen, sammenstille rapporter om aktiviteter for Leverandøren, samt for å yte andre tjenester i tilknytning til aktivitet på Appen og bruk av internett.
Ved hjelp av cookies samler Leverandøren inn informasjon til bruk for senere annonsering (remarketing). En anonymisert identitet blir laget i egne lister som brukes til målrettet annonsering basert på din brukeradferd på nett. I tillegg til at informasjonen er anonymisert (avidentifisert) stilles det krav til et visst antall brukere på listen før listen tas i bruk.
Google kan også overføre denne informasjonen til tredjeparter hvis dette kreves i henhold til lov eller i tilfeller hvor tredjeparter behandler informasjonen på vegne av Google. Google vil ikke koble din IP-adresse med annen informasjon Google har.
Google Ads er en betalt annonseringsplattform hvor annonsør betaler per annonseklikk. Annonser fra plattformen kan vises på andre kanaler enn Google – for eksempel Youtube, blogger mv. For å nå opp på Google søk benyttes Google Ads. Dataen som samles inn via Google Ads analyseres i neste rekke via Google Analytics for å optimalisere Leverandørens bruk.
Leverandøren benytter Google Ads og sporer handlinger som et resultat av klikk på en annonse, vil Google samle informasjon for å tilpasse annonser for brukere basert på interesser og tidligere nettstedsbruk. Denne målrettingen bidrar til en mer relevant markedsføring for deg som bruker. I henhold til Google sin personvernpolitikk samles det ikke inn eller brukes informasjon som kan brukes til å identifisere individer.
Nettsiden bruker Google Ads. Google Ads brukes for å annonsere på tredjeparts nettsider til tidligere besøkende på våre sider. Dette kan være i form av en annonse på Googles søk, en side i Google Display Network.
Du kan lese mer om Googles policy for bruk av personopplysninger her: https://www.google.com/intl/no/policies/privacy/
Du kan melde deg ut av Googles bruk av cookies (opt-out) for annonsering via Googles side om annonsering og personvern: http://www.google.se/policies/privacy/ads 
Dersom du ikke ønsker å bli sporet, kan du deaktivere bruk av cookies (opt-out) på din maskin via nettleseren, det gjør du her: https://tools.google.com/dlpage/gaoptout 
Du kan melde deg av Google remarkering her: https://support.google.com/ads/answer/2662922?hl=no 
Facebook pixel er en plug-in eller en programvare som ligger på Leverandørens nettsted og som samler informasjon til Facebook. Pixelen samler informasjon fra cookies som ligger i din nettleser, og sender dette tilbake til Facebook hvor informasjonen blir samlet sammen med data fra din Facebookprofil.
Facebook står som eier av Instagram, Messenger, Instant Articles og Audience Network, og bruk av Facebook pixel dekker derfor også disse kanalene.
Informasjon som samles kan være knyttet til din brukeratferd (demografiske data som alder, kjønn, interesser) eller handlinger du utfører som klikk på lenker. Informasjonen er kun tilgjengelig på et 
anonymisert og aggregert nivå. Informasjonen brukes til analyseformål, målrettet annonsering basert på din brukeratferd (remarketing) samt til å identifisere målgrupper for annonsering som har en profil som er lik din og derfor har en potensiell mulighet for å ha et likt handlingsmønster eller behov.
Informasjon samlet inn via Facebook pixel, lagres fra 90 dager til 2 år. Informasjonen lagres på servere hos Facebook i USA.
Ved bruk av Facebook i forbindelse med annonsering vil Facebook i de aller fleste tilfeller være behandlingsansvarlig. Det er dog noen unntak:
Bruk av Facebook Lead Ads, et verktøy for å samle inn epostadresser via Facebooksidene, der både Facebook og Leverandøren vil være behandlingsansvarlige. Dersom Leverandøren vil ta i bruk dette verktøyet, vil informasjon om brukerens rettigheter være tydelig angitt i annonsen hvor brukeren frivillig legger fra seg persondata.
Opplasting av eksisterende e-postlister for målrettet annonsering på Facebook. Dersom Leverandøren vil bruke dette verktøyet vil det kun være rettet mot potensielle kunder som frivillig har gitt sitt samtykke til dette.
Du kan melde deg av Facebook remarkering her: https://www.facebook.com/adpreferences/advertisers/?entry_product=ad_settings_screen 
Hotjar er en nettanalysetjeneste. Leverandøren bruker analyseverktøyet Hotjar for å analysere brukeratferd på nettsidene. Verktøyet brukes for å gjøre nettstedet mer brukervennlig. Informasjon som samles er bevegelser, klikk og generell atferd på nettstedet. Informasjonen samles på bakgrunn av din IP-adresse, men denne blir anonymisert og informasjon vises kun på et aggregert nivå. Data lagres i maksimalt 365 dager.
Du kan lese mer om Hotjars policy for bruk av personopplysninger her: https://www.hotjar.com/privacy/
Du kan ekskludere Hotjar tracking her: https://www.hotjar.com/privacy/do-not-track/ 
Mailchimp er en amerikansk markedsføringsautomatiseringsplattform og e-postmarkedsføringstjeneste.
Du kan lese mer om Mailchimps policy for bruk av personopplysninger her:
https://mailchimp.com/help/about-the-general-data-protection-regulation/#What_does_Mailchimp_do_to_c omply%3F 
Stripe er en tjeneste som driver nettbasert betalingsbehandling og handelsløsninger for internettbedrifter i alle størrelser. 
Du kan lese mer om Stripes policy for bruk av personopplysninger her:
https://stripe.com/en-gb-no/privacy 

UX Cam en analysetjeneste for apper. 
Du kan lese mer om UX Cams policy for bruk av personopplysninger her:
https://uxcam.com/privacy-policy 
Posthoq en analysetjeneste for apper og webtjenester. 

Du kan lese mer om Posthoqs policy for bruk av personopplysninger her:
https://posthog.com/privacy 


Zapier er et online automatiseringsverktøy som kobler favorittappene dine. Du kan koble to eller flere apper for å automatisere oppgaver uten å kode eller bruke utviklere for å bygge integrasjoner. Det er enkelt nok til at hvem som helst kan bygge sine egne med bare noen få klikk. Vi bruker Zapier for at flere av våre apper kan kobles direkte sammen.
https://zapier.com/privacy </p>

<h3>6. Hvor lenge lagrer Leverandøren personopplysningene dine?</h3>
<p>Leverandøren lagrer dine personopplysninger så lenge det er nødvendig for det formål personopplysningene ble samlet inn for.
Det betyr for eksempel at personopplysninger som Leverandøren behandler på grunnlag av ditt samtykke slettes umiddelbart hvis du trekker ditt samtykke.
Personopplysninger Leverandøren behandler for å oppfylle en avtale med deg slettes når avtalen er oppfylt og alle plikter som følger av avtaleforholdet er oppfylt. Personopplysninger vil imidlertid bli lagret så lenge det er nødvendig for å oppfylle formålet med behandlingen eller lovpålagte plikter Leverandøren har.
Andre typer personopplysninger (eksempelvis informasjon gitt i forbindelse med henvendelser til Leverandøren og ved bruk av Leverandørens tjenester på nettsiden) vil gjerne ha et kortere lagringsbehov. Brukerrelatert data (slik som kommunikasjon du har hatt med andre brukere og transaksjoner), vil fortsatt kunne være lagret i Tjenesten i inntil 6 måneder for å ivareta informasjonsbehov ovenfor andre brukere eller offentlige myndigheter.
For øvrig vil Leverandøren slette personopplysninger om deg dersom du ber Leverandøren om det, med mindre Leverandøren har en lovpålagt plikt eller annet rettslig grunnlag til å oppbevare personopplysningene videre.
</p>
<h3>
7. Dine rettigheter når Leverandøren behandler personopplysninger om deg</h3>
<p>Som den registrerte etter personopplysningsloven og EUs personvernforordning kalt GDPR, har du visse rettigheter til dine egne personopplysninger Leverandøren er behandlingsansvarlig for. Du har rett til å kreve innsyn, retting eller sletting av personopplysningene Leverandøren behandler om deg. Du har videre rett til å kreve begrenset behandling og rette innsigelse mot behandlingen. Etter vilkårene i personopplysningsloven og GDPR har den registrerte også rett til kreve dataportabilitet, og dette innebærer blant annet at du kan be oss om å overføre personopplysninger til deg eller til en annen behandlingsansvarlig.
Du har også rett til ikke å være gjenstand for en avgjørelse som utelukkende er basert på automatisert behandling som har rettsvirkning for, eller på tilsvarende måte i betydelig grad, påvirker deg. I noen tilfeller kan du be oss om å begrense behandlingen av personopplysninger.
Du kan lese mer om innholdet i dine rettigheter som den registrerte, på Datatilsynets nettside: www.datatilsynet.no 
Utøve dine rettigheter
Spørsmål eller anmodninger om innsyn i opplysninger som er registrert om deg eller utøvelse av andre rettigheter, kan rettes til Leverandørens behandlingsansvarlig (se kontaktdetaljer i pkt. 1).
For å ta i bruk dine rettigheter bes du om å sende en e-post til Leverandørens behandlingsansvarlig. Leverandøren vil be deg om å bekrefte identiteten din eller å oppgi ytterligere informasjon før Leverandøren lar deg ta i bruk dine rettigheter overfor Leverandøren. Dette gjør Leverandøren for å være sikker på at Leverandøren kun gir tilgang til dine personopplysninger til deg - og ikke noen som gir seg ut for å være deg.
Du kan til enhver tid trekke tilbake eventuelle samtykker til behandling av dine personopplysninger. Den enkleste måten å gjøre dette på er å ta kontakt med Bookd - hello@bookd.no
Leverandøren vil svare på din henvendelse så fort som mulig, og senest innen 30 dager.
Dersom du mener at Leverandøren behandler personopplysninger i strid med personvernregelverket, kan du også rette en klage til Datatilsynet (post@datatilsynet.no).
</p>

<h3>8. Endringer</h3>

<p>Hvis det skulle skje endring av de tjenestene Leverandørens tilbyr deg gjennom Appen eller endringer i regelverket om behandling av personopplysninger, kan det medføre endringer i personvernerklæringen. Leverandøren vil varsle deg om slike endringer. Ved mindre endringer vil vi normalt kunngjøre dette direkte i Tjenesten. Dersom vi gjennomfører endringer som vesentlig påvirker ditt personvern, vil du motta varsel direkte gjennom tilgjengelige kanaler som eksempelvis gjennom varsling i Tjenesten, e-post eller på sms.
<p>Behandlingsansvarlig</p>
<p>Tjenesten leveres av BOOKD AS, Fredensborgveien 22f, 0177 Oslo.</p>
<p>
Dersom du har spørsmål knyttet til BOOKD, ønsker innsyn eller vil be om retting eller sletting, kan du kontakte BOOKD på hello@bookd.no.</p>
</p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
