import React from 'react';
import { useDispatch } from 'react-redux';
import { FieldSelect } from '../../components';
import config from '../../config';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './PayoutDetailsForm.module.css';

const ocuppationOptions = findOptionsForSelectFilter('occuppations', config.custom.filters);

const FieldOccupationsSelect = ({ name, id, intl }) => {
  const dispatch = useDispatch();

  const occuppationLabel = intl.formatMessage({
    id: 'PayoutDetailsForm.occuppationLabel',
  });

  const handleChange = occupationCode => {
    const occupationName = ocuppationOptions.find(o => Number(o.key) === Number(occupationCode))
      .label;
    dispatch(updateProfile({ privateData: { occupationCode, occupationName } }));
  };

  return (
    <FieldSelect
      onChange={handleChange}
      className={css.occuppation}
      name={name}
      id={id}
      label={occuppationLabel}
    >
      {ocuppationOptions.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  );
};

export default FieldOccupationsSelect;
