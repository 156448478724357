import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemCustomNegotiationMaybe = props => {
  const { transaction, intl } = props;

  const { offerPrice } = transaction.attributes?.protectedData || {};

  const unitPrice = offerPrice && new Money(offerPrice.amount, offerPrice.currency);
  const formattedPrice = unitPrice ? formatMoney(intl, unitPrice) : null;

  return formattedPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabelBold}>
        <FormattedMessage id="BookingBreakdown.newOffer" />
      </span>
      <span className={css.itemValue}>{formattedPrice}</span>
    </div>
  ) : null;
};

LineItemCustomNegotiationMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomNegotiationMaybe;
