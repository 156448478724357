import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { util as sdkUtil } from '../../util/sdkLoader';
import config from '../../config';

const USERS_PAGE_SIZE = 40;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_USERS_REQUEST = 'app/LandingPage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/LandingPage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/LandingPage/QUERY_USERS_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userIds: [],
  queryUsersInProgress: false,
  queryUsersError: null,
  listingIds: [],
  queryListingsInProgress: false,
  queryListingsError: null,
};

const resultIds = data => data.data.map(d => d.id);

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload.data),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        queryListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingsInProgress: false,
        queryListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryUsersRequest = () => ({
  type: QUERY_USERS_REQUEST,
});
export const queryUsersSuccess = response => ({
  type: QUERY_USERS_SUCCESS,
  payload: { data: response.data },
});
export const queryUsersError = e => ({
  type: QUERY_USERS_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});
export const queryListingsSuccess = response => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUsers = () => async (dispatch, getState, sdk) => {
  dispatch(queryUsersRequest());

  return integrationAPI.users
    .query({
      meta_role: config.userProfileTalentType,
      perPage: USERS_PAGE_SIZE,
      include: ['profileImage'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryUsersSuccess(response.data));
      return response.data;
    })
    .catch(e => dispatch(queryUsersError(storableError(e))));
};

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  return sdk.listings
    .query({
      pub_listingType: 'profile',
      include: ['author', 'author.profileImage', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.my-variant', 'variants.my-variant-crop2x'],
      'imageVariant.my-variant': sdkUtil.objectQueryString({
        w: 400,
        h: 600,
        fit: 'scale',
      }),
      'imageVariant.my-variant-crop2x': sdkUtil.objectQueryString({
        w: 800,
        h: 1200,
        fit: 'scale',
      }),
      'limit.images': 1,
    })
    .then(response => {
      console.log(response);
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState()),
    // Query listings
    dispatch(queryListings()),

    // Query users
    dispatch(queryUsers()),
  ]);
};
