import classNames from 'classnames';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import React, { Component } from 'react';
import {
  AvatarLarge,
  BookingPanel,
  Modal,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import config from '../../config';
import { InvoicePaymentForm, SendMessageForm } from '../../forms';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  TRANSITION_REQUEST_BOOKING_AFTER_ENQUIRY,
  txHasBeenDelivered,
  txIsAccepted,
  txIsCanceled,
  txIsCancelledBeforePayment,
  txIsCustomerPaymentPending,
  txIsCustomerRequestChange,
  txIsDeclinedByCustomer,
  txIsDeclinedByProvider,
  txIsEnquired,
  txIsExpiredBeforePayment,
  txIsOfferMade,
  txIsPaymentExpired,
  txIsPaymentSuccessful,
  txIsRequested,
  txIsPendingInvoice,
  txIsAcceptedOffSession,
} from '../../util/transaction';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { isMobileSafari } from '../../util/userAgent';

// These are internal components that make this file more readable.
import { types as sdkTypes } from '../../util/sdkLoader';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import OrderActionButtonsMaybe from './OrderActionButtonsMaybe';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_CANCELED,
  HEADING_CHANGE_REQUESTED,
  HEADING_CUSTOMER_PAYMENT_PENDING,
  HEADING_CUSTOMER_PAYMENT_SUCCESSFUL,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_EXPIRED,
  HEADING_OFFER_MADE,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_CUSTOMER_PENDING_INVOICE,
  HEADING_ACCEPTED_OFF_SESSION,
  HEADING_OFF_SESSION_PAYMENT_EXPIRED,
} from './PanelHeading';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import CancelActionButtonMaybe from './CancelActionButtonMaybe';

import css from './TransactionPanel.module.css';

const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isInvoicePaymentModalOpen: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.openInvoicePaymentModal = this.openInvoicePaymentModal.bind(this);
    this.handlePayWithInvoice = this.handlePayWithInvoice.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  openInvoicePaymentModal() {
    this.setState({ isInvoicePaymentModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  handlePayWithInvoice(values) {
    const { transaction, onPayWithInvoice } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const currentTransactionId = currentTransaction.id.uuid;

    onPayWithInvoice(currentTransactionId, values)
      .then(r => this.setState({ isInvoicePaymentModalOpen: false }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptError,
      declineError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      productListings,
      handleMakePayment,
      openBookingDetailsForm,
      openMakeOfferForm,
      onRequestChange,
      requestChangeInProgress,
      requestChangeError,
      onAcceptOffer,
      onDeclineOffer,
      onCancelRequest,
      cancelRequestError,
      initiateOrderInProgress,
      initiateOrderError,
      onPayWithInvoice,
      payWithInvoiceInProgress,
      payWithInvoiceError,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const currentTransactionId = currentTransaction.id.uuid;
    const currentTransition = currentTransaction.attributes.lastTransition;
    const { offerPrice, productListingId } = currentTransaction.attributes.protectedData || {};
    const acceptParams = { offerPrice, productListingId };

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    //BookingBreakdown
    const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
    const listingAttributes = ensureListing(transaction.listing)?.attributes || {};

    const { extraInfo, location, eventType, hospitalityRider, technicalRider, numGuest } = loaded
      ? transaction.attributes.protectedData
      : {};

    const extraInfoLabel = intl.formatMessage({ id: 'BookingDetailsModal.extraInfo' });
    const eventTypeLabel = intl.formatMessage({ id: 'BookingDetailsModal.eventType' });
    const hospitalRiderLabel = intl.formatMessage({ id: 'BookingDetailsModal.hospitalityRider' });
    const technicalRiderLabel = intl.formatMessage({ id: 'BookingDetailsModal.technicalRider' });
    const numGuestLabel = intl.formatMessage({ id: 'BookingDetailsModal.numGuest' });
    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_BOOKING_AFTER_ENQUIRY);

        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showCancelButton: isCustomer,
        };
      } else if (txIsExpiredBeforePayment(tx)) {
        return {
          headingState: HEADING_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsOfferMade(tx)) {
        return {
          headingState: HEADING_OFFER_MADE,
          showDetailCardHeadings: isCustomer,
          showNewOfferActionButtons: isCustomer && !isProviderBanned,
        };
      } else if (txIsCustomerRequestChange(tx)) {
        return {
          headingState: HEADING_CHANGE_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showNewOfferActionButtons: isCustomer && !isProviderBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showMakePaymentButton: isCustomer,
          showCancelButton: isCustomer,
        };
      } else if (txIsAcceptedOffSession(tx)) {
        return {
          headingState: HEADING_ACCEPTED_OFF_SESSION,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showListingTitle: false,
        };
      } else if (txIsDeclinedByCustomer(tx) || txIsDeclinedByProvider(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCancelledBeforePayment(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCustomerPaymentPending(tx)) {
        return {
          headingState: HEADING_CUSTOMER_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
          showCancelButton: isCustomer,
        };
      } else if (txIsPaymentSuccessful(tx)) {
        return {
          headingState: HEADING_CUSTOMER_PAYMENT_SUCCESSFUL,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPendingInvoice(tx)) {
        return {
          headingState: HEADING_CUSTOMER_PENDING_INVOICE,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        const isOffSessionExpiration = lastTransition === TRANSITION_EXPIRE_OFF_SESSION_PAYMENT;

        const headingState = isOffSessionExpiration
          ? HEADING_OFF_SESSION_PAYMENT_EXPIRED
          : HEADING_PAYMENT_EXPIRED;

        return {
          headingState: headingState,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData } = currentListing.attributes;
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentTransaction.attributes?.protectedData?.title || currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentTransaction.attributes?.protectedData?.price;
    const productListingPrice = price && new Money(price.amount, price.currency);
    const bookingSubTitle = productListingPrice
      ? `${formatMoney(intl, productListingPrice)} ${intl.formatMessage({
          id: unitTranslationKey,
        })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptError={acceptError}
        declineError={declineError}
        onAcceptSale={() => onAcceptSale(currentTransactionId, acceptParams)}
        onDeclineSale={() => onDeclineSale(currentTransactionId)}
        openMakeOfferForm={openMakeOfferForm}
      />
    );

    const orderButtons = (
      <OrderActionButtonsMaybe
        showNewOfferActionButtons={stateData.showNewOfferActionButtons}
        showMakePaymentActionButton={stateData.showMakePaymentButton}
        handleMakePayment={handleMakePayment}
        onAcceptOffer={() => onAcceptOffer(currentTransactionId, acceptParams)}
        onDeclineOffer={() => onDeclineOffer(currentTransactionId)}
        onRequestChange={() => onRequestChange(currentTransactionId)}
        onPayWithInvoiceClick={this.openInvoicePaymentModal}
        payWithInvoiceError={payWithInvoiceError}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptError={acceptError}
        declineError={declineError}
        requestChangeError={requestChangeError}
        requestChangeInProgress={requestChangeInProgress}
        currentUser={currentUser}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const viewFullBookingDetails = !stateData.showBookingPanel && (
      <div className={css.viewFullBooking} onClick={openBookingDetailsForm}>
        {intl.formatMessage({ id: 'TransactionPanel.viewFullBooking' })}
      </div>
    );

    const cancelRequestErrorMessage = cancelRequestError ? (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.cancelRequestFailed" />
      </p>
    ) : null;

    const cancelRequestButton = (
      <CancelActionButtonMaybe
        show={stateData.showCancelButton}
        onAction={() => onCancelRequest(currentTransactionId, currentTransition)}
      />
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />
            <div className={css.chat}>
              <div className={css.bookingDetailsMobile}>
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
                {cancelRequestButton}
                {cancelRequestErrorMessage}
              </div>

              {savePaymentMethodFailed ? (
                <p className={css.genericError}>
                  <FormattedMessage
                    id="TransactionPanel.savePaymentMethodFailed"
                    values={{ paymentMethodsPageLink }}
                  />
                </p>
              ) : null}

              <h3 className={css.feedHeading}>
                <FormattedMessage id="TransactionPanel.bookingSummery" />
              </h3>
              <div className={css.bookingSummery}>
                <hr className={css.totalDivider} />
                <div className={css.flexItemRow}>
                  <span className={css.itemTitle}>Lokasjon</span>
                  <span className={css.item}>{location}</span>
                </div>
                <div className={css.transactionInfo}>
                  <div className={css.flexItemRow}>
                    <span className={css.itemTitle}>{eventTypeLabel}</span>
                    <span className={css.item}>{eventType}</span>
                  </div>
                  <div className={css.flexItemRow}>
                    <span className={css.itemTitle}>{numGuestLabel}</span>
                    <span className={css.item}>{numGuest}</span>
                  </div>
                  <hr className={css.totalDivider} />
                  {extraInfo && (
                    <div className={css.itemRow}>
                      <div className={css.itemTitle}>{extraInfoLabel}</div>
                      <div>{extraInfo}</div>
                    </div>
                  )}
                  {hospitalityRider && (
                    <div className={css.itemRow}>
                      <div className={css.itemTitle}>{hospitalRiderLabel}</div>
                      <div>{hospitalityRider}</div>
                    </div>
                  )}
                  {technicalRider && (
                    <div className={css.itemRow}>
                      <div className={css.itemTitle}>{technicalRiderLabel}</div>
                      <div>{technicalRider}</div>
                    </div>
                  )}
                  <hr className={css.totalDivider} />
                </div>
              </div>
              <FeedSection
                rootClassName={css.feedContainer}
                currentTransaction={currentTransaction}
                currentUser={currentUser}
                fetchMessagesError={fetchMessagesError}
                fetchMessagesInProgress={fetchMessagesInProgress}
                initialMessageFailed={initialMessageFailed}
                messages={messages}
                oldestMessagePageFetched={oldestMessagePageFetched}
                onOpenReviewModal={this.onOpenReviewModal}
                onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                totalMessagePages={totalMessagePages}
              />
              {showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              )}

              {stateData.showSaleButtons ? (
                <div className={css.mobileActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showNewOfferActionButtons || stateData.showMakePaymentButton ? (
                <div className={css.mobileActionButtons}>{orderButtons}</div>
              ) : null}
            </div>
          </div>
          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  productListings={productListings}
                  initiateOrderInProgress={initiateOrderInProgress}
                  initiateOrderError={initiateOrderError}
                />
              ) : null}
              <div className={css.fullBookingDetailsDesktop}>{viewFullBookingDetails}</div>
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showNewOfferActionButtons || stateData.showMakePaymentButton ? (
                <div className={css.desktopActionButtons}>{orderButtons}</div>
              ) : null}
              <div className={css.desktopCancelRequestButton}>
                {cancelRequestErrorMessage}
                {cancelRequestButton}
              </div>
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <Modal
          id="InvoicePaymentModal"
          isOpen={this.state.isInvoicePaymentModalOpen}
          onClose={() => this.setState({ isInvoicePaymentModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <InvoicePaymentForm
            inProgress={payWithInvoiceInProgress}
            onSubmit={this.handlePayWithInvoice}
          />
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptError: null,
  declineError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,
  handleMakePayment: func,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptError: propTypes.error,
  declineError: propTypes.error,

  onAcceptOffer: func.isRequired,
  onDeclineOffer: func.isRequired,
  onRequestChange: func.isRequired,
  requestChangeInProgress: bool,
  requestChangeError: propTypes.error,
  onCancelRequest: func.isRequired,
  cancelRequestError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  payWithInvoiceInProgress: bool.isRequired,
  payWithInvoiceError: propTypes.error,
  onPayWithInvoice: func.isRequired,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
