import React from 'react';
import classNames from 'classnames';
import { InlineTextButton } from '..';
import { useIntl } from 'react-intl';
import IconClose from '../IconClose/IconClose';

import css from './TransactionPanel.module.css';

const CancelActionButtonMaybe = props => {
  const { className, rootClassName, show, onAction } = props;

  const intl = useIntl();

  const cancelRequestLabel = intl.formatMessage({ id: 'TransactionPanel.cancelRequest' });

  const classes = classNames(rootClassName || css.cancelButtonRoot, className);

  return show ? (
    <div className={classes}>
      <InlineTextButton className={css.cancelButton} onClick={onAction}>
        <IconClose rootClassName={css.cancelIcon} />
        {cancelRequestLabel}
      </InlineTextButton>
    </div>
  ) : null;
};

export default CancelActionButtonMaybe;
