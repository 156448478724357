import React, { useState, useEffect, Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { getProfileImage } from '../../util/helperFunc';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCityState = async (latitude, longitude) => {
  const accessToken = config.maps.mapboxAccessToken;
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      const city = data.features[0].context.find(c => c.id.startsWith('place')).text;
      const country = data.features[0].context.find(c => c.id.startsWith('country')).text;
      return { city, country };
    } else {
      throw new Error('No address found');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, geolocation, metadata } = currentListing.attributes;
  const { profileImageId, generalGenres, talents = [] } = publicData;

  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    const { lat, lng } = geolocation || {};
    const location = lat && lng ? `${lat}, ${lng}` : '';

    getCityState(lat, lng)
      .then(result => {
        const { city, country } = result;
        setCity(city);
        setCountry(country);
      })
      .catch(error => console.error(error));
  }, [geolocation]);

  //const category = publicData.talents.join(' ,  ').trim();

  let str2 = '';
  if (publicData.talents != undefined) {
    const category = publicData.talents.join(' ,  ').trim();

    //const result = category.charAt(0).toUpperCase() + category.slice(1);
    //Making the first letter in every category big
    const arr = category.split(' ');

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    str2 = arr.join(' ');
  }

  const slug = createSlug(title);
  const profileImage = getProfileImage(currentListing.images, profileImageId);

  //const genresOptions = findOptionsForSelectFilter(LISTING_GENRES_KEY, filtersConfig);
  //const genres = getGenresInfo(genresOptions, generalGenres);

  const { formattedPrice, priceTitle } = priceData(price, intl);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={profileImage}
            variants={['my-variant', 'my-variant-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.subtitle}>{str2}</div>
          <h1 className={css.stateText}>{city}</h1>
        </div>
        <div className={css.price}>
          <div className={css.perUnit}>
            <FormattedMessage id="ListingCard.from" />
          </div>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);

/*<div className={css.genresInfo}>
            <div className={css.subtitle}>
            {publicData.talents}
            </div>
          </div>*/
