import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Modal,
} from '../../components';

import css from './Footer.module.css';
import IconSocialMediaLinkeDin from '../IconSocialMediaLinkeDin/IconSocialMediaLinkeDin';
import IconSocialMediaYoutube from '../IconSocialMediaYoutube/IconSocialMediaYoutube';
import IconSocialMediaTikTok from '../IconSocialMediaTikTok/IconSocialMediaTikTok';

//BookingForm
const renderBookingLink = intl => {
  const { sendRequestForm } = config;
  const goToBookingLinkPage = intl.formatMessage({ id: 'Footer.goToSendRequest' });
  const sendRequestLink = sendRequestForm ? (
    <ExternalLink
      key="linkToRequest"
      href={sendRequestForm}
      className={css.link}
      title={goToBookingLinkPage}
    >
      <FormattedMessage id="LandingPage.sendRequestButton" />
    </ExternalLink>
  ) : null;

  return [sendRequestLink].filter(v => v != null);
};

//Blogg
const renderBloggLink = intl => {
  const { siteBlogPage } = config;
  const goToBlogPage = intl.formatMessage({ id: 'Footer.goToBlogPage' });
  const blogLink = siteBlogPage ? (
    <ExternalLink key="linkToBlogg" href={siteBlogPage} className={css.link} title={goToBlogPage}>
      <FormattedMessage id="Footer.goToBlogPage" />
    </ExternalLink>
  ) : null;

  return [blogLink].filter(v => v != null);
};

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteLinkDin, siteYouTube, siteTikTok } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const goToLInkDin = intl.formatMessage({ id: 'Footer.goToLinkDin' });
  const goToTikTok = intl.formatMessage({ id: 'Footer.goToTikTok' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYouTube ? (
    <ExternalLink key="youtubeLink" href={siteYouTube} className={css.icon} title={goToYoutube}>
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteTikTok ? (
    <ExternalLink key="tiktokLink" href={siteTikTok} className={css.icon} title={goToTikTok}>
      <IconSocialMediaTikTok />
    </ExternalLink>
  ) : null;

  const linkdinLink = siteLinkDin ? (
    <ExternalLink key="linkdinLink" href={siteLinkDin} className={css.icon} title={goToLInkDin}>
      <IconSocialMediaLinkeDin />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink, youtubeLink, linkdinLink, tiktokLink].filter(v => v != null);
};

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const bookingForm = renderBookingLink(intl);
  const bloggLink = renderBloggLink(intl);
  const classes = classNames(rootClassName || css.root, className);

  const handleOpen = () => {
    setOpen(true);
  };

  const [isOpen, setOpen] = useState(false);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="mobile" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="LandingPage.title" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <Modal
                    id={'SendRequestFormFooter'}
                    {...props}
                    isOpen={isOpen}
                    onClose={() => {
                      setOpen(false);
                      console.log('Closing modal');
                    }}
                    onManageDisableScrolling={onManageDisableScrolling}
                    className={css.infoLinks}
                  >
                    <h3>
                      <FormattedMessage id="LandingPage.formTitle" />
                    </h3>
                    <iframe
                      className={css.contactForm}
                      src="https://share-eu1.hsforms.com/1QYX7CzEjQlaV6L8kGl2EDAf657l"
                      display="frame"
                      position="relative"
                      scrolling="no"
                    ></iframe>
                  </Modal>
                  <ExternalLink onClick={handleOpen} className={css.link}>
                    <FormattedMessage
                      id="LandingPage.sendRequestButton"
                      className={css.infoSpacer}
                    />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <div>{bloggLink}</div>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
          <div>
            <p>Meld deg på i nyhetsbrevet til Bookd!</p>
            <h5>Oppdateringer fra oss i Bookd</h5>
            <iframe
              className={css.background}
              src="https://embeds.beehiiv.com/1640bb76-1c16-4777-adda-c365fd1d0374?slim=true"
              data-test-id="beehiiv-embed"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
