import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build SaleActionButtons for
// provider when state is preauthorized
const OrderActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showNewOfferActionButtons,
    showMakePaymentActionButton,
    handleMakePayment,
    acceptError,
    declineError,
    requestChangeError,
    acceptInProgress,
    declineInProgress,
    requestChangeInProgress,
    onAcceptOffer,
    onDeclineOffer,
    onRequestChange,
    onPayWithInvoiceClick,
    payWithInvoiceError,
    currentUser,
  } = props;

  const { accountType = 'profile' } = currentUser?.attributes?.profile?.protectedData || {};
  const isCompany = accountType === 'company';

  const buttonsDisabled = acceptInProgress || declineInProgress || requestChangeInProgress;

  const acceptErrorMessage = acceptError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const requestChangeErrorMessage = requestChangeError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.requestChangeFailed" />
    </p>
  ) : null;
  const payWithInvoiceErrorMessage = payWithInvoiceError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.payWithInvoiceFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const showButtons = showNewOfferActionButtons || showMakePaymentActionButton;

  const newOfferActionButtons = showNewOfferActionButtons && (
    <>
      <PrimaryButton
        rootClassName={css.acceptButton}
        inProgress={acceptInProgress}
        disabled={buttonsDisabled}
        onClick={onAcceptOffer}
      >
        <FormattedMessage id="TransactionPanel.acceptOfferButton" />
      </PrimaryButton>

      <SecondaryButton
        rootClassName={css.declineButton}
        inProgress={declineInProgress}
        disabled={buttonsDisabled}
        onClick={onDeclineOffer}
      >
        <FormattedMessage id="TransactionPanel.declineButton" />
      </SecondaryButton>

      <SecondaryButton
        rootClassName={css.otherActionButton}
        inProgress={requestChangeInProgress}
        disabled={buttonsDisabled}
        onClick={onRequestChange}
      >
        <FormattedMessage id="TransactionPanel.requestChangeButton" />
      </SecondaryButton>
    </>
  );

  const makePaymentButton = showMakePaymentActionButton && (
    <>
      <PrimaryButton onClick={handleMakePayment}>
        <FormattedMessage id="TransactionPanel.makePaymentButton" />
      </PrimaryButton>
      {isCompany && (
        <PrimaryButton onClick={onPayWithInvoiceClick}>
          <FormattedMessage id="TransactionPanel.makeInvoicePaymentButton" />
        </PrimaryButton>
      )}
    </>
  );

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
        {requestChangeErrorMessage}
        {payWithInvoiceErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {newOfferActionButtons}
        {makePaymentButton}
      </div>
    </div>
  ) : null;
};

export default OrderActionButtonsMaybe;
