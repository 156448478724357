// Filter array to eliminate falsy values
export const filterFalsyValueInArray = array => {
  if (!Array.isArray(array)) {
    throw new Error('This is not a valid array.');
  }

  const arr = array.filter(element => {
    return !!(typeof element === 'string' ? element.trim() : element);
  });

  // If the array is empty, return null
  return arr.length ? arr : null;
};

export const getProfileImage = (images, imageId) => {
  return images?.find(i => i.id.uuid === imageId);
};

export const getOptionsText = (options, keys) => {
  const filteredOptions = options.filter(option => keys?.includes(option.key));
  return filteredOptions.map(option => option.label).join(', ');
};
