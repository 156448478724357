import React, { useState } from 'react';
import { Modal, Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingHelpButton.module.css';

const BookingHelpButton = props => {


  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
          // We are just checking the value for now
        };
      

  const handleOpen = () => {
          setOpen(true);
        };
        
        
const [isOpen, setOpen] = useState(false);

  return  (
    <div className={css.layoutbuttons}>
      <button onClick={handleOpen} className={css.heroButton}>
       <FormattedMessage id="BookingHelpButton"/>
      </button>
      <Modal
        id={'SuggestionForm'}
        {...props}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <h3>
        <FormattedMessage id="LandingPage.formSuggustionTitle" />
        </h3>
        <iframe
          className={css.contactForm}
          src="https://share-eu1.hsforms.com/1QYX7CzEjQlaV6L8kGl2EDAf657l"
          display="frame"
          position="relative"
        ></iframe>
      </Modal>

    </div>
  );
};

export default BookingHelpButton;
