import { array, string } from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldCheckboxGroup, FieldCurrencyInput, FieldTextInput } from '../../components';
import IconTrashBin from '../../components/IconTrashBin/IconTrashBin';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  composeValidators,
  maxLength,
  moneySubUnitAmountAtLeast,
  required,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import AddProductImage from './AddProductImage';

import css from './EditListingProductsForm.module.css';

const TITLE_MAX_LENGTH = 60;

const { Money } = sdkTypes;

const EditProductForm = props => {
  /* STATES */
  const [uploadImageInProgress, setUploadImageInProgress] = useState(false);

  /* INTL */
  const intl = useIntl();

  /* PROPS */
  const {
    name,
    talentOptions,
    vibeOptions,
    genreOptions,
    form,
    productImage,
    setProductImage,
    onDeleteProduct,
  } = props;

  /* RENDERING */
  const requiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.required',
  });

  // Product Name
  const productNameLabel = intl.formatMessage({
    id: 'EditListingProductsForm.productName',
  });

  // Product Talents - What are your talents
  const talentsLabel = intl.formatMessage({
    id: 'EditListingProductsForm.talentsLabel',
  });
  const talentsRequiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.talentsRequired',
  });

  // Product Description
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingProductsForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );
  const descriptionMessage = intl.formatMessage({
    id: 'EditListingProductsForm.description',
  });
  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
  const descriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.descriptionRequired',
  });

  // Product Vibe
  const vibeLabel = intl.formatMessage({
    id: 'EditListingProductsForm.vibe',
  });
  const vibeRequiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.vibeRequired',
  });

  const genreRequiredMessage = intl.formatMessage({
    id: 'EditListingProductsForm.genreRequired',
  });

  // Product Genre
  const genreLabel = intl.formatMessage({
    id: 'EditListingProductsForm.genre',
  });

  // Product Timeframe
  const timeframeLabel = intl.formatMessage({
    id: 'EditListingProductsForm.timeframe',
  });

  // Product Price
  const priceLabel = intl.formatMessage({
    id: 'EditListingProductsForm.price',
  });

  const pricePlaceholder = intl.formatMessage({ id: 'EditListingProductsForm.pricePlaceholder' });
  const priceRequired = required(
    intl.formatMessage({
      id: 'EditListingProductsForm.priceRequired',
    })
  );
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingProductsForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );
  const priceValidators = config.listingMinimumPriceSubUnits
    ? composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  // Product Technical Rider
  const technicalRiderLabel = intl.formatMessage({
    id: 'EditListingProductsForm.technicalRider',
  });

  const technicalRiderPlaceholder = intl.formatMessage({
    id: 'EditListingProductsForm.technicalRiderPlaceholder',
  });

  // Product Technical Rider
  const hospitalityRiderLabel = intl.formatMessage({
    id: 'EditListingProductsForm.hospitalityRider',
  });

  // Product Hospitality Rider
  const hospitalityRiderPlaceholder = intl.formatMessage({
    id: 'EditListingProductsForm.hospitalityRiderPlaceholder',
  });

  // Delete button
  const deleteProductLabel = intl.formatMessage({ id: 'EditListingProductsForm.deleteProduct' });

  const deleteProductBtn = (
    <div className={css.deleteProductBtn} onClick={onDeleteProduct}>
      <IconTrashBin className={css.iconDelete} />
      {deleteProductLabel}
    </div>
  );

  return (
    <div className={css.editProductForm}>
      <FieldTextInput
        id={`${name}.name`}
        name={`${name}.name`}
        className={css.textField}
        type="text"
        label={productNameLabel}
        validate={required(requiredMessage)}
        autoFocus
      />
      <FieldCheckboxGroup
        className={css.checkboxes}
        id={`${name}.talents`}
        name={`${name}.talents`}
        customLabel={talentsLabel}
        validate={requiredFieldArrayCheckbox(talentsRequiredMessage)}
        options={talentOptions}
        twoColumns
      />
      <FieldTextInput
        id={`${name}.description`}
        name={`${name}.description`}
        className={css.description}
        type="textarea"
        label={descriptionMessage}
        validate={required(requiredMessage)}
      />
      <FieldCheckboxGroup
        className={css.checkboxes}
        id={`${name}.vibes`}
        name={`${name}.vibes`}
        customLabel={vibeLabel}
        validate={requiredFieldArrayCheckbox(vibeRequiredMessage)}
        options={vibeOptions}
        twoColumns
      />
      <FieldCheckboxGroup
        className={css.checkboxes}
        id={`${name}.genres`}
        name={`${name}.genres`}
        customLabel={genreLabel}
        validate={requiredFieldArrayCheckbox(genreRequiredMessage)}
        options={genreOptions}
        twoColumns
      />
      <FieldTextInput
        id={`${name}.timeframe`}
        name={`${name}.timeframe`}
        className={css.textField}
        type="text"
        label={timeframeLabel}
        validate={required(requiredMessage)}
      />
      <FieldCurrencyInput
        id={`${name}.price`}
        name={`${name}.price`}
        className={css.priceInput}
        label={priceLabel}
        placeholder={pricePlaceholder}
        currencyConfig={config.currencyConfig}
        validate={priceValidators}
      />
      <FieldTextInput
        id={`${name}.technicalRider`}
        name={`${name}.technicalRider`}
        className={css.textField}
        type="textarea"
        label={technicalRiderLabel}
        placeholder={technicalRiderPlaceholder}
      />
      <FieldTextInput
        id={`${name}.hospitalityRider`}
        name={`${name}.hospitalityRider`}
        className={css.textField}
        type="textarea"
        label={hospitalityRiderLabel}
        placeholder={hospitalityRiderPlaceholder}
      />
      <AddProductImage
        name={name}
        form={form}
        uploadImageInProgress={uploadImageInProgress}
        setUploadImageInProgress={setUploadImageInProgress}
        productImage={productImage}
        setProductImage={setProductImage}
      />
      {deleteProductBtn}
    </div>
  );
};

EditProductForm.defaultProps = {
  talentOptions: [],
  vibeOptions: [],
  genreOptions: [],
};

EditProductForm.propTypes = {
  name: string,
  talentOptions: array,
  vibeOptions: array,
  genreOptions: array,
};

export default EditProductForm;
