import classNames from 'classnames';
import { isEmpty, isEqual, union } from 'lodash';
import { bool, func, object, string } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import { modifyProductListings } from '../../containers/EditListingPage/EditListingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { EditListingProductsForm } from '../../forms';
import { createListing, updateListing, closeListing } from '../../util/api';
import { denormalisedResponseEntities, ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';

import css from './EditListingProductsPanel.module.css';

const MAX_PRICE = Number.MAX_SAFE_INTEGER;
const PRODUCT = 'product';

const TALENTS = 'talents';
const VIBES = 'vibes';
const GENRES = 'genres';

const CREATE = 'create';
const UPDATE = 'update';
const DELETE = 'delete';

const { Money } = sdkTypes;

/* METHODS */
// Map states to props for useSelector hook
const mapStatesToProps = state => {
  const { productListingIds, ...rest } = state.EditListingPage;
  const { currentUser } = state.user;

  // The listings order is wrong so that it must be reversed
  const productListings = getListingsById(state, productListingIds).reverse();

  return {
    productListings,
    currentUser,
  };
};

const getFilteredOptions = (key, filteredOptions) => {
  const allOptions = findOptionsForSelectFilter(key, config.custom.filters);

  return allOptions.filter(option => filteredOptions.includes(option.key));
};

const filterEmptyProductObject = products =>
  products.filter(product => product && !isEmpty(product));

const setupInitalProducts = productListings => {
  if (productListings.length === 0) {
    // To create the first product if there are no existing products
    return [''];
  }

  return productListings.map(listing => {
    const { title, description, price, publicData } = listing.attributes;
    const {
      technicalRider,
      hospitalityRider,
      timeframe,
      vibes = [],
      genres = [],
      talents = [],
    } = publicData;

    return {
      id: listing?.id?.uuid,
      name: title,
      description,
      price: new Money(price.amount, price.currency),
      technicalRider,
      hospitalityRider,
      timeframe,
      vibes,
      genres,
      talents,
      productImage: listing.images[0],
    };
  });
};

/* COMPONENT */
const EditListingProductsPanel = props => {
  const dispatch = useDispatch();
  const { productListings, currentUser } = useSelector(mapStatesToProps);
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    filterConfig,
  } = props;

  const currentListing = ensureOwnListing(listing);
  const { talents: generalTalents } = currentListing.attributes?.publicData;

  const classes = classNames(rootClassName || css.root, className);

  const panelTitle = <FormattedMessage id="EditListingProductsPanel.listingTitle" />;

  const filteredTalents = useMemo(() => getFilteredOptions(TALENTS, generalTalents), [
    generalTalents,
  ]);
  const filteredVibes = findOptionsForSelectFilter(VIBES, filterConfig);
  const filteredGenres = findOptionsForSelectFilter(GENRES, filterConfig);

  const generateProductListingPromises = (products, method) => {
    const filteredProducts = filterEmptyProductObject(products);

    if (filteredProducts.length === 0) {
      return [];
    }

    const promises = filteredProducts.map((product, index) => {
      const {
        id,
        name: title,
        description,
        price,
        productImage,
        technicalRider,
        hospitalityRider,
        timeframe,
        vibes,
        genres,
        talents,
      } = product;

      const imageId = productImage?.id.uuid || productImage?.imageId.uuid;

      const extendParams =
        method === CREATE ? { state: 'published', authorId: currentUser.id.uuid } : {};

      const imageParamMaybe =
        method === UPDATE && productListings[index]?.images[0].id.uuid === imageId
          ? {}
          : { images: [imageId] };

      const params = method !== DELETE && {
        title,
        description,
        price,
        publicData: {
          hospitalityRider,
          technicalRider,
          timeframe,
          vibes,
          genres,
          parentListingId: currentListing.id.uuid,
          talents,
          listingType: PRODUCT,
        },
        ...extendParams,
        ...imageParamMaybe,
      };

      return method === CREATE
        ? createListing(params)
        : method === UPDATE
        ? updateListing(id, params)
        : closeListing(id);
    });

    return promises;
  };

  const products = useMemo(() => setupInitalProducts(productListings), [productListings]);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingProductsForm
        name="products"
        className={css.form}
        initialValues={{ products }}
        initialValuesEqual={(oldValues, newValues) => isEqual(oldValues, newValues)}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { products = [] } = values;

          const productsShouldBeCreated = products.filter(
            products => !!(products && !products.id && !products.deleted)
          );
          const productsShouldBeUpdated = products.filter(
            products => !!(products && products.id && !products.deleted)
          );
          const productsShouldBeDeleted = products.filter(
            products => !!(products && products.id && products.deleted)
          );

          const createProductPromises = generateProductListingPromises(
            productsShouldBeCreated,
            CREATE
          );
          const updateProductPromises = generateProductListingPromises(
            productsShouldBeUpdated,
            UPDATE
          );
          const deleteProductPromises = generateProductListingPromises(
            productsShouldBeDeleted,
            DELETE
          );

          dispatch(
            modifyProductListings([
              ...updateProductPromises,
              ...createProductPromises,
              ...deleteProductPromises,
            ])
          ).then(responses => {
            const filteredValues = responses.reduce((current, res) => {
              const listing = denormalisedResponseEntities(res);
              const { vibes = [], genres = [] } = listing[0]?.attributes.publicData || {};
              const { productListingIds = [], generalVibes = [], generalGenres = [] } = current;
              const { price: currentListingPrice } =
                listing[0]?.attributes || new Money(MAX_PRICE, config.currency);
              const isListingRemoved = productsShouldBeDeleted.some(
                product => product?.id?.uuid === listing[0].id.uuid
              );
              const minPriceAmount = Math.min(
                current.price?.amount || MAX_PRICE,
                currentListingPrice.amount
              );

              return isListingRemoved
                ? current
                : {
                    price: new Money(minPriceAmount, config.currency),
                    productListingIds: [...productListingIds, listing[0].id.uuid],
                    generalVibes: union(generalVibes, vibes),
                    generalGenres: union(generalGenres, genres),
                  };
            }, {});

            const { price, ...publicData } = filteredValues;

            const updateValues = {
              price,
              publicData,
            };
            onSubmit(updateValues);
          });
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        talentOptions={filteredTalents}
        vibeOptions={filteredVibes}
        genreOptions={filteredGenres}
        products={products}
      />
    </div>
  );
};

EditListingProductsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  filterConfig: config.custom.filters,
};

EditListingProductsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  filterConfig: propTypes.filterConfig,
};

export default EditListingProductsPanel;
